import { css, cx } from '@emotion/css';
import {
  Badge,
  Button,
  ColorPickerInput,
  colors,
  Field,
  Icon,
  Input,
  Menu,
  MultiSelect,
  RadioButtonGroup,
  ReactMonacoEditor,
  useTheme2,
} from '@grafana/ui';
import { components } from 'api';
import { API_URL } from 'common';
import { AnimatePresence, m } from 'framer-motion';
import React, { useMemo, useState } from 'react';
import stringHash from 'string-hash';
import {
  currentProjectIdObservable,
  DEFAULT_RUNTIME_STATE,
  runtimeStateObservable,
  selectedSourceObservable,
  userObservable,
} from '../observables';
import useObservable from '../hooks/useObservable';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import _ from 'lodash';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { transformLocalName } from '../utils';

type Channel = components['schemas']['Channel'];
type ChannelGroup = components['schemas']['ChannelGroup'];

const tabs = [
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'Plotting',
    value: 'plotting',
  },
];

const ChannelMenu: React.FC<{ channel: Channel; groups: ChannelGroup[]; colors: string[] }> = ({
  channel,
  groups,
  colors,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [modifiedChannel, setModifiedChannel] = useState<components['schemas']['UpdateChannelInput']>({
    ...channel,
    plottingAttributes: channel.overridePlottingAttributes,
    isPublic: channel.overrideIsPublic,
  });
  const [channelAttributes, setChannelAttributes] = useState(
    JSON.stringify(channel.overrideChannelAttributes, null, 2)
  );
  const theme = useTheme2();
  const activeProjectId = useObservable(runtimeStateObservable, DEFAULT_RUNTIME_STATE).activeProjectId;
  const currentProjectId = useObservable(currentProjectIdObservable, 0);
  const user = useObservable(userObservable, null);
  const [isLoading, setIsLoading] = useState(false);
  const isOverride = useMemo(
    () =>
      Object.keys(channel.overrideChannelAttributes).length > 0 ||
      Object.keys(channel.overridePlottingAttributes).length > 0 ||
      typeof channel.overrideIsPublic === 'boolean',
    [channel]
  );

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        const appEvents = getAppEvents();
        setIsLoading(true);
        try {
          await fetch(`${API_URL}/api/channels/${channel.name}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...modifiedChannel,
              channelAttributes: JSON.parse(channelAttributes),
            }),
          });
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: [`Update channel (${channel.name}) Successfully`],
          });
        } catch (err) {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Update channel (${channel.name}) failed: ${err}`],
          });
        } finally {
          setIsLoading(false);
        }
      }}
      className={css({
        backgroundColor: theme.colors.background.primary,
        boxShadow: theme.shadows.z3,
        width: 300,
      })}
    >
      <div
        className={css({
          padding: 8,
          paddingTop: 10,
          paddingBottom: 4,
        })}
      >
        <p
          className={css({
            fontWeight: 600,
            margin: 0,
            fontSize: '1rem',
          })}
        >
          {transformLocalName(channel.name)}
        </p>
      </div>
      <Menu.Divider />
      <div className="p-2">
        <p className="text-sm font-medium opacity-80 italic m-0 mb-2">Channel Group</p>
        <MultiSelect
          options={groups.map((group) => ({
            label: transformLocalName(group.name),
            value: group.name,
          }))}
          value={modifiedChannel.groups}
          allowCustomValue
          onChange={(groups) => {
            setModifiedChannel((channel) => ({
              ...channel,
              groups: groups.map((group) => group.value).filter(Boolean) as string[],
            }));
          }}
        />
      </div>
      <Menu.Divider />
      {(user?.userRole === 'Admin' || user?.userRole === 'Developer' || user?.id === channel.userId) &&
        activeProjectId === channel.projectId &&
        currentProjectId === channel.projectId &&
        (channel.dataflow || channel.event || channel.import || channel.record || channel.derivative) && (
          <>
            <div className="p-2">
              <p className="text-sm font-medium opacity-80 italic m-0 mb-2">Sources</p>
              <div className="space-y-1 flex flex-col">
                {channel.dataflow && <SourceItem sourceType="Dataflow" channel={channel} />}
                {channel.event && <SourceItem sourceType="Event" channel={channel} />}
                {channel.import && <SourceItem sourceType="Import" channel={channel} />}
                {channel.record && <SourceItem sourceType="Record" channel={channel} />}
                {channel.derivative && <SourceItem sourceType="Derived" channel={channel} />}
              </div>
            </div>
            <Menu.Divider />
          </>
        )}
      <div className="p-2">
        <div className="flex items-center mb-2 w-full">
          <p className="text-sm font-medium opacity-80 italic m-0 mr-2">Attributes</p>
          {(Object.keys(channel.overrideChannelAttributes).length > 0 ||
            Object.keys(channel.overridePlottingAttributes).length > 0) && (
            <Badge text="Override" color="orange" className="mr-2" />
          )}
          <div className="ml-auto mr-0">
            <EditAttributesPopover
              channel={_.cloneDeep(channel)}
              modifiedChannel={modifiedChannel}
              setModifiedChannel={setModifiedChannel}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              channelAttributes={channelAttributes}
              setChannelAttributes={setChannelAttributes}
            />
          </div>
        </div>
        <RadioButtonGroup
          options={tabs}
          value={selectedTab}
          onChange={setSelectedTab}
          fullWidth
          className={css({
            marginBottom: 8,
          })}
        />
        <AnimatePresence mode="popLayout">
          {selectedTab === 'channel' && (
            <m.div key="channel" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <ReactMonacoEditor
                language="json"
                value={JSON.stringify(channel.channelAttributes, null, 2)}
                // onChange={(value) => {
                //   setChannelAttributes(value || '');
                // }}
                options={{
                  minimap: { enabled: false },
                  readOnly: true,
                  // lineNumbers: 'off',
                }}
                className={css({
                  minHeight: 160,
                })}
              />
            </m.div>
          )}
          {selectedTab === 'plotting' && (
            <m.div
              key="plotting"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="p-1"
            >
              <Field label="Color">
                <ColorPickerInput
                  value={
                    (channel.plottingAttributes.color as string) ||
                    colors[Math.abs(stringHash(channel.name)) % colors.length]
                  }
                  onChange={(color) => {
                    setModifiedChannel((channel) => ({
                      ...channel,
                      plottingAttributes: { ...channel.plottingAttributes, color },
                    }));
                  }}
                  disabled
                />
              </Field>
              {/* <Field label="Visibility">
                <Switch
                  value={!(modifiedChannel.plottingAttributes?.hidden || false)}
                  onChange={(_event) => {
                    setModifiedChannel((channel) => ({
                      ...channel,
                      plottingAttributes: {
                        ...channel.plottingAttributes,
                        hidden: !channel.plottingAttributes?.hidden,
                      },
                    }));
                  }}
                />
              </Field> */}
              <Field label="Max Data Points (second)" className="mb-0">
                <Input
                  type="number"
                  value={(channel.plottingAttributes.max_data_points_per_second as number) ?? 24}
                  // onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  //   setModifiedChannel((channel) => ({
                  //     ...channel,
                  //     plottingAttributes: {
                  //       ...channel.plottingAttributes,
                  //       max_data_points_per_second: Number(ev.target.value || ''),
                  //     },
                  //   }));
                  // }}
                  disabled
                />
              </Field>
            </m.div>
          )}
        </AnimatePresence>
      </div>
      <Menu.Divider />
      <div className="p-2">
        <div className="flex items-center mb-2 w-full">
          <p className="text-sm font-medium opacity-80 italic m-0 mr-2">Access</p>
          {typeof channel.overrideIsPublic === 'boolean' && (
            <>
              <Badge text="Override" color="orange" className="mr-2" />
              {/*<div*/}
              {/*  role="button"*/}
              {/*  className="ml-auto mr-0 border-0 rounded-md bg-transparent px-3 py-1.5 text-sm hover:bg-black/5 dark:hover:bg-white/5"*/}
              {/*  onClick={() => {*/}
              {/*    setModifiedChannel((channel) => ({*/}
              {/*      ...channel,*/}
              {/*      isPublic: undefined,*/}
              {/*    }));*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Reset*/}
              {/*</div>*/}
            </>
          )}
        </div>
        <RadioButtonGroup
          options={[
            {
              label: 'Public',
              value: true,
            },
            {
              label: 'Private',
              value: false,
            },
          ]}
          value={modifiedChannel.isPublic ?? channel.isPublic}
          onChange={(isPublic) => {
            setModifiedChannel((channel) => ({
              ...channel,
              isPublic,
            }));
          }}
          fullWidth
        />
      </div>
      <Menu.Divider />
      <div className="p-2">
        <Button
          icon={isLoading ? 'spinner' : 'save'}
          variant="success"
          disabled={isLoading}
          type="submit"
          fullWidth
          className="w-full"
        >
          <>{isLoading ? 'Saving' : 'Save'}</>
        </Button>
        {isOverride && (
          <Button
            icon={isLoading ? 'spinner' : 'save'}
            disabled={isLoading}
            onClick={() => {
              setModifiedChannel((channel) => ({
                ...channel,
                channelAttributes: {},
                plottingAttributes: {},
                isPublic: undefined,
              }));
              setChannelAttributes('{}');
            }}
            type="submit"
            fullWidth
            className="w-full mt-2"
          >
            <>{isLoading ? 'Saving' : 'Reset And Save'}</>
          </Button>
        )}
      </div>
    </form>
  );
};

function SourceItem({
  sourceType,
  channel,
}: {
  sourceType: 'Dataflow' | 'Event' | 'Import' | 'Record' | 'Derived';
  channel: Channel;
}) {
  const theme = useTheme2();

  return (
    <>
      <div
        className={cx(
          'p-2 rounded-md flex',
          css({
            backgroundColor: theme.colors.background.secondary,
          })
        )}
        role="button"
        onClick={() => {
          selectedSourceObservable.next({
            sourceType,
            channel,
          });
        }}
      >
        <div className="flex-1">
          <p className="m-0">{sourceType}</p>
          {sourceType === 'Dataflow' && <p className="m-0 text-sm opacity-80">{channel.dataflow?.name}</p>}
          {sourceType === 'Import' && <p className="m-0 text-sm opacity-80">{channel.import?.name}</p>}
          {sourceType === 'Record' && <p className="m-0 text-sm opacity-80">{channel.record?.testId}</p>}
          {sourceType === 'Derived' && <p className="m-0 text-sm opacity-80">{channel.derivative?.name}</p>}
          {sourceType === 'Event' && (
            <p className="m-0 text-sm opacity-80">
              {channel.event?.name} ({channel.event?.id})
            </p>
          )}
        </div>
        <div className="flex items-center p-1">
          <Icon name="angle-right" size="lg" />
        </div>
      </div>
    </>
  );
}

function EditAttributesPopover({
  channel,
  modifiedChannel,
  setModifiedChannel,
  selectedTab,
  setSelectedTab,
  channelAttributes,
  setChannelAttributes,
}: {
  channel: Channel;
  modifiedChannel: components['schemas']['UpdateChannelInput'];
  setModifiedChannel: React.Dispatch<React.SetStateAction<components['schemas']['UpdateChannelInput']>>;
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  channelAttributes: string;
  setChannelAttributes: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
  });
  const theme = useTheme2();

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="border-0 rounded-md bg-transparent px-3 py-1.5 text-sm hover:bg-black/5 dark:hover:bg-white/5"
          >
            Override <Icon name="angle-right" size="sm" />
          </Popover.Button>
          <Popover.Panel ref={setPopperElement} style={popperStyles.popper} {...attributes.popper}>
            <div
              className={cx(
                css({
                  backgroundColor: theme.colors.background.primary,
                  boxShadow: theme.shadows.z3,
                  width: 300,
                }),
                'p-2 pt-0'
              )}
            >
              <div
                className={cx(
                  css({
                    padding: 8,
                    paddingTop: 10,
                  }),
                  'flex items-center justify-between'
                )}
              >
                <p
                  className={css({
                    fontWeight: 600,
                    margin: 0,
                    fontSize: '1rem',
                  })}
                >
                  Override Attributes
                </p>
                {/*<Button*/}
                {/*  icon="times"*/}
                {/*  variant="secondary"*/}
                {/*  onClick={() => {*/}
                {/*    setModifiedChannel((channel) => ({*/}
                {/*      ...channel,*/}
                {/*      channelAttributes: {},*/}
                {/*      plottingAttributes: {},*/}
                {/*    }));*/}
                {/*    setChannelAttributes('{}');*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Reset*/}
                {/*</Button>*/}
              </div>
              <RadioButtonGroup
                options={tabs}
                value={selectedTab}
                onChange={setSelectedTab}
                fullWidth
                className="mb-2"
              />
              <AnimatePresence mode="popLayout">
                {selectedTab === 'channel' && (
                  <m.div key="channel" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <ReactMonacoEditor
                      language="json"
                      value={channelAttributes}
                      onChange={(value) => {
                        setChannelAttributes(value || '');
                      }}
                      options={{
                        minimap: { enabled: false },
                      }}
                      className={css({
                        minHeight: 160,
                      })}
                    />
                  </m.div>
                )}
                {selectedTab === 'plotting' && (
                  <m.div
                    key="plotting"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="p-1"
                  >
                    <Field label="Color">
                      <ColorPickerInput
                        value={
                          (_.merge(channel.sourcePlottingAttributes, modifiedChannel.plottingAttributes)
                            .color as string) || colors[Math.abs(stringHash(channel.name)) % colors.length]
                        }
                        onChange={(color) => {
                          setModifiedChannel((channel) => ({
                            ...channel,
                            plottingAttributes: { ...channel.plottingAttributes, color },
                          }));
                        }}
                      />
                    </Field>
                    <Field label="Max Data Points (second)">
                      <Input
                        type="number"
                        value={
                          (_.merge(channel.sourcePlottingAttributes, modifiedChannel.plottingAttributes)
                            .max_data_points_per_second as number) ?? 24
                        }
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                          setModifiedChannel((channel) => ({
                            ...channel,
                            plottingAttributes: {
                              ...channel.plottingAttributes,
                              max_data_points_per_second: Number(ev.target.value || ''),
                            },
                          }));
                        }}
                      />
                    </Field>
                  </m.div>
                )}
              </AnimatePresence>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}

export default ChannelMenu;
