import { Field, Input, TextArea } from '@grafana/ui';
import useObservable from 'hooks/useObservable';
import { projectListObservable } from 'observables';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';

export type ProjectFormValues = {
  name: string;
  // description: string;
};

type ProjectFormProps = {
  defaultValues?: ProjectFormValues;
  extraFields?: React.ReactNode;
  actions?: React.ReactNode;
  onSubmit?: (values: ProjectFormValues) => void;
  disabled?: {
    name?: boolean;
  };
};

export default function ProjectForm({ defaultValues, extraFields, actions, onSubmit, disabled }: ProjectFormProps) {
  const projects = useObservable(projectListObservable, []);
  const projectNames = useMemo(() => projects.map((project) => project.name), [projects]);
  const form = useForm<ProjectFormValues>({
    defaultValues: defaultValues ?? {
      name: '',
      // description: '',
    },
    resolver: async (values) => {
      if (!values.name) {
        return {
          values,
          errors: {
            name: {
              type: 'required',
              message: 'Project name is required',
            },
          },
        };
      }
      if (!defaultValues && projectNames.includes(values.name)) {
        return {
          values,
          errors: {
            name: {
              type: 'unique',
              message: 'Project name must be unique',
            },
          },
        };
      }
      return {
        values,
        errors: {},
      };
    },
    reValidateMode: 'onChange',
  });

  return (
    <form className="gap-2 flex flex-col" onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}>
      <Field label="Name" invalid={!!form.formState.errors.name} error={form.formState.errors.name?.message} required>
        <Input {...form.register('name', { required: 'Project name is required' })} disabled={disabled?.name} />
      </Field>
      {/*<Field*/}
      {/*  label="Description"*/}
      {/*  invalid={!!form.formState.errors.description}*/}
      {/*  error={form.formState.errors.description?.message}*/}
      {/*>*/}
      {/*  <TextArea {...form.register('description')} />*/}
      {/*</Field>*/}
      {extraFields}
      {actions}
    </form>
  );
}
