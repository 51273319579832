import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Drawer, Form, FormsOnSubmit, HorizontalGroup } from '@grafana/ui';
import React, { useRef } from 'react';
import { POST } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import UpdateIsDirty from '../utils/UpdateIsDirty';
import FunctionForm, { FunctionFormValues } from './FunctionForm';

interface FunctionAddDrawerProps {
  onClose: () => void;
}

const FunctionAddDrawer: React.FC<FunctionAddDrawerProps> = ({ onClose }) => {
  const [ConfirmModalComponent, showConfirm] = useConfirm({
    title: 'Unsaved Changes',
    body: 'You are in the middle of adding a function, are you sure you want to discard it?',
    confirmText: 'Discard',
    onConfirm: onClose,
  });
  const isDirtyRef = useRef(false);

  const handleClose = () => {
    if (isDirtyRef.current) {
      showConfirm();
      return;
    }
    onClose();
  };

  const handleSubmit: FormsOnSubmit<FunctionFormValues> = async (data) => {
    try {
      if (data.pinLevel === 'SYSTEM') {
        return;
      }
      const path =
        data.type === 'Source'
          ? ('source' as const)
          : data.type === 'Process'
          ? ('process' as const)
          : data.type === 'Sink'
          ? ('sink' as const)
          : data.type === 'Import'
          ? ('import' as const)
          : data.type === 'PostSource'
          ? ('post-source' as const)
          : data.type === 'EventTrigger'
          ? ('event-trigger' as const)
          : data.type === 'Export'
          ? ('export' as const)
          : ('derivative' as const);
      const { error } = await POST(`/api/${path}-functions` as const, {
        body: {
          inputType: data.inputType,
          outputType: data.outputType,
          function: {
            name: data.name,
            description: data.description,
            schema: JSON.parse(data.schema),
            code: data.code,
            pinLevel: data.pinLevel,
          },
        },
      });
      const appEvents = getAppEvents();
      if (!error) {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Function added successfully'],
        });
        onClose();
      } else {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Function added failed: ${error?.message ?? 'Server unreachable'}`],
        });
      }
    } catch (err) {
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Function added failed: ${err}`],
      });
    }
  };

  return (
    <Drawer title="Add Function" size="md" onClose={handleClose}>
      <>
        <Form
          onSubmit={handleSubmit}
          width="100%"
          maxWidth="none"
          className="pb-4"
          defaultValues={{
            type: 'Source',
            inputType: 'TIMESERIES',
            outputType: 'TIMESERIES',
            schema: JSON.stringify(
              {
                $schema: 'http://json-schema.org/draft-07/schema',
                properties: {
                  host: {
                    type: 'string',
                    pattern:
                      '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
                  },
                  port: {
                    type: 'number',
                    minimum: 1024,
                    maximum: 65535,
                  },
                },
                required: ['host', 'port'],
              },
              null,
              4
            ),
            code: DEFAULT_CODE,
            pinLevel: 'UNPINNED',
          }}
        >
          {(props) => (
            <>
              <UpdateIsDirty isDirtyRef={isDirtyRef} isDirty={props.formState.isDirty} />
              <FunctionForm {...props} />
              <HorizontalGroup spacing="md">
                {/*<Button*/}
                {/*  type="button"*/}
                {/*  onClick={async () => {*/}
                {/*    await handleTest(props.getValues());*/}
                {/*  }}*/}
                {/*  variant="success"*/}
                {/*>*/}
                {/*  Test*/}
                {/*</Button>*/}
                <Button type="submit">Add</Button>
              </HorizontalGroup>
            </>
          )}
        </Form>
        {ConfirmModalComponent}
      </>
    </Drawer>
  );
};

const DEFAULT_CODE = `
## Source function ##

# from typing import Generator, Callable
# 
# def handle(
#     args: dict, 
#     subscribe: Callable[[dict], Generator[dict, None, None]],
#     get_channels_data: Callable[[dict], list[dict]],
# ) -> Generator:
#     # TODO: implement your function here
#     # yield data every time you want to send data to the next function
#     # e.g.
#     # while True:
#     #     data = get_data()
#     #     yield data
#     #     
#     # subscribe() subscribe to channels and channel groups with optional historical data
#     #   @return a generator that yields data from subscribed channels, one time frame at a time
#     #     data will always comes with the following order:
#     #       1. channel registry list with subscribed channels (full list)
#     #       2. historical data if start is set
#     #       3. live data
#     #       *  repeat 1 and 3 if channel registry list is updated
#     #       ** when one of the subscribed time frame is updated, repeat 1-3 for that time frame
#     # e.g.
#     # subscribe({
#     #     'channels': ['ch1', 'ch2'], # optional
#     #     'channel_groups': ['group1', 'group2'], # optional
#     #     'start': 0.0, # (timestamp, rfc3339, relative time (e.g. -10w/d/m/s/ms/us/ns)) optional, used for historical data
#     # })
#     #
#     # get_channels_data() get imported, recorded, and historical channels data
#     #   @return a tuple (list of channel registry, list of time frame)
#     # e.g.
#     # (registry, data) = get_channels_data({
#     #    'channels': ['ch1', 'ch2'], # optional
#     #    'channel_groups': ['group1', 'group2'], # optional
#     #    'start': 0.0, # (timestamp, rfc3339, relative time (e.g. -10w/d/m/s/ms/us/ns)) optional, default to 0.0, relative time is based on the first received data point
#     #    'end': time.time(), # timestamp only, optional, default to current time, ignored if <= start
#     #    'first_n': 1000, # optional
#     #    'last_n': 1000, # optional, ignored if first_n is set
#     #    'down_sample': {
#     #        'method': 'ABS_MAX', # FIRST | LAST | MAX | MIN | ABS_MAX | MEAN | MEDIAN | MIDDLE
#     #        'max_points': 1000, # 0 to disable
#     #        'start': None, # timestamp only, optional. If None, derived from get_channels_data.start
#     #        'end': None, # timestamp only, optional. If None, derived from get_channels_data.end
#     #    }, # optional
#     # })

## Process function ##

# from typing import Iterable, Generator
#
# def handle(data: Iterable, args: dict) -> Generator:
#     # TODO: implement your function here
#     # loop over iterable to get data yield from previous function
#     # yield data every time you want to send data to the next function
#     # e.g.
#     # for d in data:
#     #     yield process(d)

## Sink function ##

# from typing import Iterable, Callable, List

# def handle(
#     data: Iterable,
#     args: dict,
#     register: Callable[[List[dict]], None],
#     send: Callable[[dict], None],
# ):
#     # TODO: implement your function here
#     # loop over iterable to get data yield from previous function
#     # call register() to register channels and upsert attributes
#     # call send() to send data to grafana, channels in same dict is considered as same time frame (same table in grafana)
#     # e.g.
#     # register([{ 'name': 'ch1' }, { 'name': 'ch2', 'channel_attributes': {}, 'plotting_attributes': {}])
#     # import time
#     # for d in data:
#     #     send({
#     #         'time': [time.time()], # required, [float, float, ...] | np.ndarray[float]
#     #         'ch1': [d['ch1']], # [float, float, ...] | np.ndarray[float]
#     #         'ch2': [d['ch2']], # [float, float, ...] | np.ndarray[float]
#     #     }) # list supported

## Import function ##

# from typing import Callable, List

# def handle(
#     files: List[str],
#     args: dict,
#     register: Callable[[List[dict]], None],
#     send: Callable[[dict], None],
# ):
#     #registered_channels = {}
#     #for file in files:
#     #  dataset = pd.read_csv(file)
#     #  data = dataset.to_dict('list');
#     #  for key in data.keys():
#     #    if key not in registered_channels and key != 'time':
#     #      registered_channels[key] = { 'name': key }
#     #  register(list(registered_channels.values()))
#     #  send(data)

## Post Source function ##

# from typing import Generator, Callable, List, Union

# def handle(
#     it: Generator[dict, None, None],
#     args: dict,
#     duration: float, # duration in seconds
#     end: Union[float, None], # None = now, timestamp = absolute seconds, negative seconds = relative seconds to now
#     get_channels_data: Callable[[dict], List[dict]],
#     ack: Callable # ack() after all get_channels_data() is done
# ):
#     # for data in it:
#     #     yield data
#     #
#     # get_channels_data() get imported, recorded, event and historical channels data
#     #   @return a tuple (list of channel registry, list of time frame)
#     # e.g.
#     # import time
#     # (registry, data) = get_channels_data({
#     #    'channels': ['ch1', 'ch2'], # optional
#     #    'channel_groups': ['group1', 'group2'], # optional
#     #    'start': 0.0, # (timestamp, rfc3339, relative time (e.g. -10w/d/m/s/ms/us/ns)) optional, default to 0.0
#     #    'end': time.time(), # timestamp only, optional, default to current time, ignored if <= start
#     #    'first_n': 1000, # optional
#     #    'last_n': 1000, # optional, ignored if first_n is set
#     #    'down_sample': {
#     #        'method': 'ABS_MAX', # FIRST | LAST | MAX | MIN | ABS_MAX | MEAN | MEDIAN | MIDDLE
#     #        'max_points': 1000, # 0 to disable
#     #        'start': None, # timestamp only, optional. If None, derived from get_channels_data.start
#     #        'end': None, # timestamp only, optional. If None, derived from get_channels_data.end
#     #    }, # optional
#     # })

## Event Trigger function ##

# from typing import Iterable, Callable

# def handle(
#     data: Iterable,
#     args: dict,
#     send: Callable[dict, None],
# ):
#     for d in data:
#       # if condition is met, trigger event
#       send({
#         'name': 'event_name', # event name (optional)
#         'type': 'event_type', # event type
#         'triggered_by': ['ch1', 'ch2'], # list of channel names that trigger the event
#         'channels_to_plot': [['ch1', 'ch2'], ['ch3']], # list of list of channel names to plot, each list will be plotted in a separate graph
#         'channels_to_store': ['ch1', 'ch2', 'ch3'], # list of channel names to store
#         'metadata': {}, # additional metadata (optional)，
#         'before': 5, # seconds before the event to record (optional)
#         'after': 5, # seconds after the event to record (optional)
#       })

## Export function ##

# from typing import Iterable, List, Callable

# def handle(
#     dir: str,
#     args: dict,
#     get_channels_data: Callable[[dict], List[dict]],
# ):
#     # TODO: get data from get_channels_data() and export to dir
#     #
#     # get_channels_data() get imported, recorded, event, and historical channels data
#     #   @return a tuple (list of channel registry, list of time frame)
#     # e.g.
#     # import time
#     # (registry, data) = get_channels_data({
#     #    'channels': ['ch1', 'ch2'], # optional
#     #    'channel_groups': ['group1', 'group2'], # optional
#     #    'start': 0.0, # (timestamp, rfc3339, relative time (e.g. -10w/d/m/s/ms/us/ns)) optional, default to 0.0
#     #    'end': time.time(), # timestamp only, optional, default to current time, ignored if <= start
#     #    'first_n': 1000, # optional
#     #    'last_n': 1000, # optional, ignored if first_n is set
#     #    'down_sample': {
#     #        'method': 'ABS_MAX', # FIRST | LAST | MAX | MIN | ABS_MAX | MEAN | MEDIAN | MIDDLE
#     #        'max_points': 1000, # 0 to disable
#     #        'start': None, # timestamp only, optional. If None, derived from get_channels_data.start
#     #        'end': None, # timestamp only, optional. If None, derived from get_channels_data.end
#     #    }, # optional
#     # })

## Derived function ##

# from typing import Iterable, Callable

# def handle(
#     it: Iterable,
#     args: dict,
#     send: Callable[dict, None],
# ):
#    # TODO: implement your function here
#    # Derived function can only append new channels to the existing record or import, it cannot modify existing channels
#    # Each new channel is only allowed to be sent once in each derivative run. Every subsequent send() call with the same channel name will be ignored
#    # Every time the derivative runs, it will start with a clean state, no data will be carried over from the previous run. All previous data will be removed
#
#    for data in it:
#      for frame in data:
#         ## Record ##
#         # send({
#         #   'type': 'record',
#         #   'test_id': '10000',
#         #   'friend': None, # friend channel name, optional, this allow new channels to be plotted together with existing channels
#         #   'registry': [], # list of new channel registry
#         #   'data': {}, # time frame
#         # })
#      
#         ## Import ##
#         # send({
#         #   'type': 'import',
#         #   'name': '10000',
#         #   'friend': None, # friend channel name, optional, this allow new channels to be plotted together with existing channels
#         #   'registry': [], # list of new channel registry
#         #   'data': {}, # time frame
#         # })
#      
#         ## Import ##
#         # send({
#         #   'type': 'event',
#         #   'id': 1,
#         #   'friend': None, # friend channel name, optional, this allow new channels to be plotted together with existing channels
#         #   'registry': [], # list of new channel registry
#         #   'data': {}, # time frame
#         # })
`.trim();

export default FunctionAddDrawer;
