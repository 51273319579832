import { WS_API_URL } from 'common';
import React, { useEffect, useMemo, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import EventTriggerListItem from './EventTriggerListItem';
import { Dataflow, EventTrigger } from '../../types';
import dayjs from 'dayjs';
import { currentProjectIdObservable } from '../../observables';
import useObservable from '../../hooks/useObservable';
import { Icon, Input } from '@grafana/ui';

const EventTriggerList = () => {
  const [eventTriggers, setEventTriggers] = useState<EventTrigger[]>([]);
  const projectId = useObservable(currentProjectIdObservable, 0);
  const [keywords, setKeywords] = useState('');

  const filteredEventTriggers = useMemo(() => {
    if (!keywords) {
      return eventTriggers;
    }
    return eventTriggers.filter((eventTrigger) => {
      return eventTrigger.name.toLowerCase().includes(keywords.toLowerCase());
    });
  }, [eventTriggers, keywords]);

  useEffect(() => {
    if (projectId === 0) {
      return;
    }

    const ws = new ReconnectingWebSocket(WS_API_URL + '/api/event-triggers/watch?project_id=' + projectId);

    ws.onmessage = (event) => {
      const eventTriggers = JSON.parse(event.data) as EventTrigger[];
      setEventTriggers(eventTriggers.sort((a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1)));
    };

    return () => {
      ws.close();
    };
  }, [projectId]);

  return (
    <div className="flex flex-col">
      <div className="flex mb-2 p-1 space-x-1">
        <Input
          placeholder="Search Event Triggers"
          prefix={<Icon name="search" />}
          value={keywords}
          onChange={(e) => setKeywords(e.currentTarget.value)}
        />
      </div>
      {filteredEventTriggers.map((eventTrigger) => (
        <EventTriggerListItem key={eventTrigger.id} eventTrigger={eventTrigger} />
      ))}
      {eventTriggers.length === 0 ? (
        <div className="p-2 text-center text-gray-500">No event triggers created yet</div>
      ) : (
        filteredEventTriggers.length === 0 && (
          <div className="p-2 text-center text-gray-500">No event triggers found</div>
        )
      )}
    </div>
  );
};

export default EventTriggerList;
