import React, { useRef, useState } from 'react';
import { Button, Drawer, Form, FormsOnSubmit } from '@grafana/ui';
import useConfirm from '../../hooks/useConfirm';
import UpdateIsDirty from '../utils/UpdateIsDirty';
import DerivativeForm, { DerivativeFormValues } from './DerivativeForm';
import { POST } from '../../client';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';

interface DerivativeAddDrawerProps {
  onClose: () => void;
}

const DerivativeAddDrawer: React.FC<DerivativeAddDrawerProps> = ({ onClose }) => {
  const [result, setResult] = useState<string>('');
  const [ConfirmModalComponent, showConfirm] = useConfirm({
    title: 'Unsaved Changes',
    body: 'You have unsaved changes, are you sure you want to discard it?',
    confirmText: 'Discard',
    onConfirm: onClose,
  });
  const isDirtyRef = useRef(false);
  // const [isRunning, setIsRunning] = useState(false);
  // const controllerRef = useRef<AbortController | null>(null);

  const handleSubmit: FormsOnSubmit<DerivativeFormValues> = async (data) => {
    try {
      if (!data.source || !data.sink) {
        return;
      }

      data.processes.forEach((process, index) => {
        process.sequence = index + 1;
      });

      const { error } = await POST('/api/derivatives', {
        body: {
          name: data.name,
          source: data.source,
          sourceArgs: data.sourceArgs,
          processes: data.processes,
          sink: data.sink,
          sinkArgs: data.sinkArgs,
        },
      });

      const appEvents = getAppEvents();

      if (!error) {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Event trigger added successfully'],
        });
        onClose();
      } else {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Event trigger add failed: ${error.message}`],
        });
      }
    } catch (err) {
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Event trigger add failed: ${err}`],
      });
    }
  };

  // const handleRun = async (values: DerivativeFormValues) => {
  //   if (!values.source || !values.sink) {
  //     return;
  //   }
  //
  //   setIsRunning(true);
  //
  //   let firstMessage = true;
  //
  //   controllerRef.current = new AbortController();
  //
  //   // const t = setTimeout(() => {
  //   //   controllerRef.current?.abort();
  //   // }, 10000);
  //
  //   try {
  //     setResult('Running...');
  //
  //     values.processes.forEach((process, index) => {
  //       process.sequence = index + 1;
  //     });
  //
  //     const response = await fetch(`${API_URL}/api/derivatives/${}/run`, {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         name: values.name,
  //         source: values.source,
  //         sourceArgs: values.sourceArgs,
  //         processes: values.processes,
  //         sink: values.sink,
  //         sinkArgs: values.sinkArgs,
  //       }),
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       signal: controllerRef.current!.signal,
  //     });
  //     if (!response.ok) {
  //       setResult('Error occurred');
  //       return;
  //     }
  //     const reader = response.body?.getReader();
  //     if (!reader) {
  //       setResult('Error occurred');
  //       return;
  //     }
  //     let done = false;
  //     while (!done) {
  //       const { value, done: doneValue } = await reader.read();
  //       done = doneValue;
  //       if (value) {
  //         const text = new TextDecoder().decode(value);
  //         if (firstMessage) {
  //           setResult(text + '\n');
  //           firstMessage = false;
  //         } else {
  //           setResult((t) => t + text + '\n');
  //         }
  //       }
  //     }
  //   } catch (err) {
  //     if (err instanceof AbortError) {
  //       if (firstMessage) {
  //         setResult('Stopped');
  //       } else {
  //         setResult((t) => t + 'Stopped');
  //       }
  //     } else if (err instanceof Error) {
  //       if (firstMessage) {
  //         setResult('Stopped');
  //       } else {
  //         setResult((t) => t + 'Stopped');
  //       }
  //     } else {
  //       setResult(`${err}`);
  //     }
  //   } finally {
  //     setIsRunning(false);
  //   }
  // };

  const handleClose = () => {
    if (!isDirtyRef.current) {
      onClose();
      return;
    }
    showConfirm();
  };

  // useEffect(() => {
  //   return () => {
  //     controllerRef.current?.abort();
  //   };
  // }, []);

  return (
    <Drawer title="Add Derived Channel" size="md" onClose={handleClose} scrollableContent>
      <>
        <Form
          defaultValues={{
            name: '',
            source: null,
            sourceArgs: {},
            processes: [],
            sink: null,
            sinkArgs: {},
          }}
          className="p-4"
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <UpdateIsDirty isDirtyRef={isDirtyRef} isDirty={props.formState.isDirty} />
              <DerivativeForm {...props} result={result} />
              <Button type="submit">Add</Button>
              {/*<div className="space-x-3">*/}
              {/*  {!isRunning ? (*/}
              {/*    <Button*/}
              {/*      key="run"*/}
              {/*      type="button"*/}
              {/*      onClick={async (e) => {*/}
              {/*        await handleRun(props.getValues());*/}
              {/*      }}*/}
              {/*      variant="success"*/}
              {/*    >*/}
              {/*      Add and Run*/}
              {/*    </Button>*/}
              {/*  ) : (*/}
              {/*    <Button*/}
              {/*      key="stop"*/}
              {/*      type="button"*/}
              {/*      onClick={() => {*/}
              {/*        controllerRef.current?.abort();*/}
              {/*      }}*/}
              {/*      variant="destructive"*/}
              {/*    >*/}
              {/*      Stop*/}
              {/*    </Button>*/}
              {/*  )}*/}
              {/*</div>*/}
            </>
          )}
        </Form>
        {ConfirmModalComponent}
      </>
    </Drawer>
  );
};

export default DerivativeAddDrawer;
