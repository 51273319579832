import useObservable from 'hooks/useObservable';
import { recordListObservable } from 'observables';
import React, { useEffect, useMemo, useState } from 'react';
import { Record } from '../../types';
import RecordEditModal from './RecordEditModal';
import RecordListItem from './RecordListItem';
import { Icon, Input } from '@grafana/ui';

const RecordList = () => {
  const records = useObservable(recordListObservable, []);
  const [selectedRecord, setSelectedRecord] = useState<Record | null>(null);
  const [keywords, setKeywords] = useState('');

  const filteredRecords = useMemo(() => {
    if (!keywords) {
      return records;
    }
    return records.filter((record) => {
      return record.testId.toLowerCase().includes(keywords.toLowerCase());
    });
  }, [records, keywords]);

  useEffect(() => {
    if (selectedRecord) {
      const index = records.findIndex((record) => record.id === selectedRecord.id);
      if (index === -1) {
        setSelectedRecord(null);
      } else {
        setSelectedRecord(records[index]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  return (
    <div className="flex flex-col">
      <div className="flex mb-2 p-1 space-x-1">
        <Input
          placeholder="Search Records"
          prefix={<Icon name="search" />}
          value={keywords}
          onChange={(e) => setKeywords(e.currentTarget.value)}
        />
      </div>
      {filteredRecords.map((record) => (
        <RecordListItem
          key={record.id}
          record={record}
          onEditClick={() => {
            setSelectedRecord(record);
          }}
        />
      ))}
      {records.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-gray-500">No data recorded previously</p>
        </div>
      ) : filteredRecords.length === 0 ? (
        <div className="p-2 text-center text-gray-500">No records found</div>
      ) : null}
      {selectedRecord && <RecordEditModal record={selectedRecord} onClose={() => setSelectedRecord(null)} />}
    </div>
  );
};

export default RecordList;
