import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Checkbox, Field, Icon, Modal, Select } from '@grafana/ui';
import { GET, POST } from 'client';
import React, { useCallback, useEffect, useMemo } from 'react';
import ProjectForm, { ProjectFormValues } from './ProjectForm';
import { baseProjectObservable, projectListObservable } from '../../observables';
import { ProjectUser } from '../../types';
import { Disclosure } from '@headlessui/react';
import { ChevronRight } from 'lucide-react';
import useObservable from '../../hooks/useObservable';

export default function ProjectAddModal({ onClose }: { onClose: () => void }) {
  const [activateAfterCreate, setActivateAfterCreate] = React.useState(false);
  const [baseProjectUsers, setBaseProjectUsers] = React.useState<ProjectUser[]>([]);
  const [loadingMessage, setLoadingMessage] = React.useState('');
  const [selectedUsers, setSelectedUsers] = React.useState<number[]>([]);
  const baseProject = useObservable(baseProjectObservable, null);
  const [selectedProjectToCopyFrom, setSelectedProjectToCopyFrom] = React.useState<number | null>(null);
  const projects = useObservable(projectListObservable, []);

  const baseProjectDeveloperUsers = useMemo(
    () => baseProjectUsers.filter((user) => user.userRole === 'Developer'),
    [baseProjectUsers]
  );
  const baseProjectUserUsers = useMemo(
    () => baseProjectUsers.filter((user) => user.userRole === 'User'),
    [baseProjectUsers]
  );
  const baseProjectClientUsers = useMemo(
    () => baseProjectUsers.filter((user) => user.userRole === 'Client'),
    [baseProjectUsers]
  );

  const selectedDeveloperUsers = useMemo(
    () => baseProjectDeveloperUsers.filter((user) => selectedUsers.includes(user.userId)),
    [baseProjectDeveloperUsers, selectedUsers]
  );

  const selectedUserUsers = useMemo(
    () => baseProjectUserUsers.filter((user) => selectedUsers.includes(user.userId)),
    [baseProjectUserUsers, selectedUsers]
  );

  const selectedClientUsers = useMemo(
    () => baseProjectClientUsers.filter((user) => selectedUsers.includes(user.userId)),
    [baseProjectClientUsers, selectedUsers]
  );

  const handleCreate = useCallback(
    async (values: ProjectFormValues) => {
      const appEvents = getAppEvents();
      try {
        setLoadingMessage('Setting up project...');
        const response = await POST('/api/projects', {
          body: values,
          params: {
            query: {
              activate: activateAfterCreate,
              copyDataflowsFrom: selectedProjectToCopyFrom,
            },
          },
        });
        if (response.error) {
          throw new Error(response.error.message);
        }
        const newProjectId = response.data.id;
        if (selectedUsers.length > 0) {
          const totalSelectedUsers = selectedUsers.length;
          let currentFinishedUsers = 0;
          const userAdded = () => {
            currentFinishedUsers++;
            setLoadingMessage(`Adding users (${currentFinishedUsers}/${totalSelectedUsers})`);
          };
          const promises = [
            ...selectedDeveloperUsers.map((user) =>
              POST('/api/projects/{project_id}/users', {
                body: {
                  userId: user.userId,
                  role: 'Developer',
                },
                params: {
                  path: {
                    project_id: newProjectId,
                  },
                },
              }).then(userAdded)
            ),
            ...selectedUserUsers.map((user) =>
              POST('/api/projects/{project_id}/users', {
                body: {
                  userId: user.userId,
                  role: 'User',
                },
                params: {
                  path: {
                    project_id: newProjectId,
                  },
                },
              }).then(userAdded)
            ),
            ...selectedClientUsers.map((user) =>
              POST('/api/projects/{project_id}/users', {
                body: {
                  userId: user.userId,
                  role: 'Client',
                },
                params: {
                  path: {
                    project_id: newProjectId,
                  },
                },
              }).then(userAdded)
            ),
          ];
          await Promise.all(promises);
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: ['Project created successfully'],
          });
          onClose();
        }
      } catch (e) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Failed to create project: ${e instanceof Error ? e.message : e}`],
        });
      } finally {
        setLoadingMessage('');
      }
    },
    [
      activateAfterCreate,
      selectedUsers,
      selectedDeveloperUsers,
      selectedUserUsers,
      selectedClientUsers,
      selectedProjectToCopyFrom,
      onClose,
    ]
  );

  useEffect(() => {
    if (!baseProject) {
      return;
    }

    // Fetch base project users
    (async () => {
      try {
        const { data, error } = await GET('/api/projects/{project_id}/users', {
          params: {
            path: {
              project_id: baseProject.id,
            },
          },
        });
        if (error) {
          throw new Error(error.message);
        }
        setBaseProjectUsers(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [baseProject, setBaseProjectUsers]);

  useEffect(() => {
    if (selectedProjectToCopyFrom || !baseProject) {
      return;
    }
    setSelectedProjectToCopyFrom(baseProject.id);
  }, [baseProject, selectedProjectToCopyFrom, setSelectedProjectToCopyFrom]);

  return (
    <Modal title="Add Project" isOpen={true} onDismiss={onClose}>
      <ProjectForm
        onSubmit={handleCreate}
        extraFields={
          <>
            <Field label={`Copy dataflows and functions from`}>
              <Select
                options={projects.map((project) => ({ label: project.name, value: project.id }))}
                value={selectedProjectToCopyFrom}
                onChange={(value) => {
                  setSelectedProjectToCopyFrom(value.value ?? null);
                }}
              />
            </Field>
            <Field label={`Copy users from base project (${baseProject?.name})`}>
              <div className="flex flex-col items-start mt-1 space-y-2">
                <Disclosure defaultOpen>
                  <Disclosure.Button className="mr-2 flex items-center" as="div">
                    <ChevronRight className="ui-open:rotate-90 h-4 w-4 mr-2" />
                    <Checkbox
                      label={`All Developers (${selectedDeveloperUsers.length}/${baseProjectDeveloperUsers.length})`}
                      checked={selectedDeveloperUsers.length === baseProjectDeveloperUsers.length}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setSelectedUsers(
                            Array.from(
                              new Set([...selectedUsers, ...baseProjectDeveloperUsers.map((user) => user.userId)])
                            )
                          );
                        } else {
                          setSelectedUsers(
                            selectedUsers.filter(
                              (userId) => !baseProjectDeveloperUsers.some((user) => user.userId === userId)
                            )
                          );
                        }
                      }}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col pt-1 pl-8 max-h-72 overflow-y-auto">
                    {baseProjectDeveloperUsers.map((user) => (
                      <div
                        key={user.userId}
                        className="border-l border-0 border-solid border-black/50 dark:border-white/50 pl-4 py-1"
                      >
                        <Checkbox
                          label={user.name || user.login}
                          className="flex justify-start"
                          checked={selectedUsers.includes(user.userId)}
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              setSelectedUsers([...selectedUsers, user.userId]);
                            } else {
                              setSelectedUsers(selectedUsers.filter((userId) => userId !== user.userId));
                            }
                          }}
                        />
                      </div>
                    ))}
                  </Disclosure.Panel>
                </Disclosure>
                <Disclosure defaultOpen>
                  <Disclosure.Button className="mr-2 flex items-center" as="div">
                    <ChevronRight className="ui-open:rotate-90 h-4 w-4 mr-2" />
                    <Checkbox
                      label={`All Users (${selectedUserUsers.length}/${baseProjectUserUsers.length})`}
                      checked={selectedUserUsers.length === baseProjectUserUsers.length}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setSelectedUsers(
                            Array.from(new Set([...selectedUsers, ...baseProjectUserUsers.map((user) => user.userId)]))
                          );
                        } else {
                          setSelectedUsers(
                            selectedUsers.filter(
                              (userId) => !baseProjectUserUsers.some((user) => user.userId === userId)
                            )
                          );
                        }
                      }}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col pt-1 pl-8 max-h-72 overflow-y-auto">
                    {baseProjectUserUsers.map((user) => (
                      <div
                        key={user.userId}
                        className="border-l border-0 border-solid border-black/50 dark:border-white/50 pl-4 py-1"
                      >
                        <Checkbox
                          label={user.name || user.login}
                          className="flex justify-start"
                          checked={selectedUsers.includes(user.userId)}
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              setSelectedUsers([...selectedUsers, user.userId]);
                            } else {
                              setSelectedUsers(selectedUsers.filter((userId) => userId !== user.userId));
                            }
                          }}
                        />
                      </div>
                    ))}
                  </Disclosure.Panel>
                </Disclosure>
                <Disclosure defaultOpen>
                  <Disclosure.Button className="mr-2 flex items-center" as="div">
                    <ChevronRight className="ui-open:rotate-90 h-4 w-4 mr-2" />
                    <Checkbox
                      label={`All Clients (${selectedClientUsers.length}/${baseProjectClientUsers.length})`}
                      checked={selectedClientUsers.length === baseProjectClientUsers.length}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setSelectedUsers(
                            Array.from(
                              new Set([...selectedUsers, ...baseProjectClientUsers.map((user) => user.userId)])
                            )
                          );
                        } else {
                          setSelectedUsers(
                            selectedUsers.filter(
                              (userId) => !baseProjectClientUsers.some((user) => user.userId === userId)
                            )
                          );
                        }
                      }}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col pt-1 pl-8 max-h-72 overflow-y-auto">
                    {baseProjectClientUsers.map((user) => (
                      <div
                        key={user.userId}
                        className="border-l border-0 border-solid border-black/50 dark:border-white/50 pl-4 py-1"
                      >
                        <Checkbox
                          label={user.name || user.login}
                          className="flex justify-start"
                          checked={selectedUsers.includes(user.userId)}
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              setSelectedUsers([...selectedUsers, user.userId]);
                            } else {
                              setSelectedUsers(selectedUsers.filter((userId) => userId !== user.userId));
                            }
                          }}
                        />
                      </div>
                    ))}
                  </Disclosure.Panel>
                </Disclosure>
              </div>
            </Field>
            {loadingMessage !== '' && (
              <p className="text-sm mt-2">
                <Icon name="fa fa-spinner" className="mr-2" />
                {loadingMessage}
              </p>
            )}
          </>
        }
        actions={
          <Modal.ButtonRow>
            <Button variant="secondary" fill="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setActivateAfterCreate(false);
              }}
              disabled={loadingMessage !== ''}
            >
              Create
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setActivateAfterCreate(true);
              }}
              disabled={loadingMessage !== ''}
            >
              Create and Activate
            </Button>
          </Modal.ButtonRow>
        }
      />
    </Modal>
  );
}
