import { getAppEvents } from '@grafana/runtime';
import { POST } from 'client';
import { WS_API_URL } from 'common';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { BehaviorSubject, map } from 'rxjs';
import type { Channel, ChannelGroup, GlobalUser, Project, ProjectUser, Record, RuntimeState, UserInfo } from 'types';
import dayjs from 'dayjs';

export const selectedSourceObservable = new BehaviorSubject<{
  sourceType: 'Dataflow' | 'Import' | 'Record' | 'Derived' | 'Event';
  channel: Channel;
} | null>(null);
export const recordListObservable = new BehaviorSubject<Record[]>([]);
export const DEFAULT_RUNTIME_STATE: RuntimeState = {
  recording: null,
  activeProjectId: 0,
};
export const runtimeStateObservable = new BehaviorSubject<RuntimeState>(DEFAULT_RUNTIME_STATE);
// export const channelListObservable = new BehaviorSubject<Channel[]>([]);
// export const channelGroupListObservable = new BehaviorSubject<ChannelGroup[]>([]);
export const projectListObservable = new BehaviorSubject<Project[]>([]);
export const globalUserListObservable = new BehaviorSubject<GlobalUser[]>([]);
export const projectUserListObservable = new BehaviorSubject<ProjectUser[]>([]);
// export const realtimeChannelListObservable = channelListObservable.pipe(
//   map((channels) => {
//     return channels.filter((channel) => !!channel.dataflowId);
//   })
// );
// export const importedChannelListObservable = channelListObservable.pipe(
//   map((channels) => {
//     return channels.filter((channel) => !!channel.importId);
//   })
// );
// export const recordedChannelListObservable = channelListObservable.pipe(
//   map((channels) => {
//     return channels.filter((channel) => !!channel.recordId);
//   })
// );

export const userObservable = new BehaviorSubject<UserInfo | null>(null);
export const currentProjectIdObservable = new BehaviorSubject<number>(0);
export const baseProjectObservable = projectListObservable.pipe(
  map((projects) => {
    return projects.find((project) => project.base) ?? null;
  })
);

fetch('/server/api/me')
  .then((response) => response.json() as Promise<UserInfo>)
  .then((user) => {
    userObservable.next(user);
    currentProjectIdObservable.next(user.orgId);
  });

currentProjectIdObservable.subscribe(async (projectId) => {
  if (projectId === 0) {
    return;
  }

  const subscriptions: ReconnectingWebSocket[] = [];

  const ws = new ReconnectingWebSocket(WS_API_URL + '/api/runtime-state/watch?project_id=' + projectId);

  ws.onmessage = (event) => {
    const runtimeState = JSON.parse(event.data) as RuntimeState;
    runtimeStateObservable.next(runtimeState);
  };

  subscriptions.push(ws);

  // const ws2 = new ReconnectingWebSocket(WS_API_URL + '/api/channels/watch?project_id=' + projectId);
  //
  // ws2.onmessage = (event) => {
  //   const channels = JSON.parse(event.data) as Channel[];
  //   channelListObservable.next(channels);
  // };
  //
  // subscriptions.push(ws2);
  //
  // const ws3 = new ReconnectingWebSocket(WS_API_URL + '/api/channel-groups/watch?project_id=' + projectId);
  //
  // ws3.onmessage = (event) => {
  //   const channelGroups = JSON.parse(event.data) as ChannelGroup[];
  //   channelGroupListObservable.next(channelGroups);
  // };
  //
  // subscriptions.push(ws3);

  const ws4 = new ReconnectingWebSocket(WS_API_URL + '/api/projects/watch');

  ws4.onmessage = (event) => {
    const projects = JSON.parse(event.data);
    projectListObservable.next(projects);
  };

  subscriptions.push(ws4);

  const me = userObservable.value;

  if (me?.userRole === 'Admin' || me?.userRole === 'Developer') {
    const ws5 = new ReconnectingWebSocket(WS_API_URL + '/api/records/watch?project_id=' + projectId);

    ws5.onmessage = (event) => {
      const records = JSON.parse(event.data) as Record[];
      recordListObservable.next(records);
    };

    subscriptions.push(ws5);

    const ws6 = new ReconnectingWebSocket(WS_API_URL + '/api/users/watch');

    ws6.onmessage = (event) => {
      const users = JSON.parse(event.data);
      globalUserListObservable.next(users);
    };

    subscriptions.push(ws6);

    const ws7 = new ReconnectingWebSocket(WS_API_URL + `/api/projects/${projectId}/users/watch`);

    ws7.onmessage = (event) => {
      const users = JSON.parse(event.data);
      projectUserListObservable.next(users);
    };

    subscriptions.push(ws7);
  }

  return () => {
    subscriptions.forEach((ws) => ws.close());
  };
});

runtimeStateObservable.subscribe((state) => {
  if (state.recording) {
    const el = document.createElement('button');
    el.id = 'stop-recording-button';
    const renderTime = (startedAt: string) => {
      const recordedTime = secondsToHumanTime(dayjs().diff(dayjs(startedAt).utc(true), 'second'));
      el.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" style="width:32px;height:32px;margin-right:12px" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-stop"><circle cx="12" cy="12" r="10"/><rect x="9" y="9" width="6" height="6" rx="1"/></svg>
        <p id="recording-button" style="color: white; font-size: 18px; font-weight: 600; margin: 0;">${recordedTime}</p>
      `;
    };
    renderTime(state.recording.startedAt);
    const t = setInterval(() => {
      if (!state.recording) {
        clearInterval(t);
        return;
      }
      renderTime(state.recording.startedAt);
    }, 1000);
    el.style.position = 'fixed';
    el.style.bottom = '30px';
    el.style.right = '30px';
    el.style.zIndex = '9999';
    el.className = '!text-white';
    el.style.backgroundColor = 'hsl(0, 100%, 50%)';
    el.style.border = 'none';
    el.style.borderRadius = '28px';
    el.style.padding = '12px 20px';
    el.style.display = 'flex';
    el.style.justifyContent = 'center';
    el.style.alignItems = 'center';
    el.style.cursor = 'pointer';
    el.title = 'Stop Recording';
    el.onclick = async () => {
      if (!state.recording) {
        return;
      }
      try {
        await POST(`/api/records/{id}/finish`, {
          params: {
            path: {
              id: state.recording.id,
            },
          },
        });
        const appEvents = getAppEvents();
        appEvents.publish({
          type: 'alert-success',
          payload: [`Stop recording (${state.recording.testId}) Successfully`],
        });
        clearInterval(t);
      } catch (e) {
        console.error(e);
      }
    };
    document.body.appendChild(el);
  } else {
    const el = document.getElementById('stop-recording-button');
    if (el) {
      el.remove();
    }
  }
});

// 30s -> 00:30
// 60s -> 01:00
// 1200s -> 20:00
// 3600s -> 01:00:00
function secondsToHumanTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = seconds % 60;
  return `${hours > 0 ? hours + ':' : ''}${String(minutes).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
}
