import { WS_API_URL } from 'common';
import React, { useEffect, useMemo, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { DataImport } from '../../types';
import DataImportListItem from './DataImportListItem';
import useObservable from '../../hooks/useObservable';
import { currentProjectIdObservable } from '../../observables';
import { Icon, Input } from '@grafana/ui';
import dayjs from 'dayjs';

const DataImportList = ({
  selectedDataImport,
  setSelectedDataImport,
}: {
  selectedDataImport: DataImport | null;
  setSelectedDataImport: React.Dispatch<React.SetStateAction<DataImport | null>>;
}) => {
  const [dataImports, setDataImports] = useState<DataImport[]>([]);
  const currentProjectId = useObservable(currentProjectIdObservable, 0);
  const [keywords, setKeywords] = useState('');

  const filteredDataImports = useMemo(() => {
    if (!keywords) {
      return dataImports;
    }
    return dataImports.filter((dataImport) => {
      return dataImport.name.toLowerCase().includes(keywords.toLowerCase());
    });
  }, [dataImports, keywords]);

  useEffect(() => {
    if (currentProjectId === 0) {
      return;
    }

    const ws = new ReconnectingWebSocket(WS_API_URL + '/api/data-imports/watch?project_id=' + currentProjectId);

    ws.onmessage = (event) => {
      const dataImports = JSON.parse(event.data) as DataImport[];
      setDataImports(dataImports.sort((a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1)));
    };

    return () => {
      ws.close();
    };
  }, [currentProjectId]);

  useEffect(() => {
    if (selectedDataImport) {
      const index = dataImports.findIndex((dataImport) => dataImport.id === selectedDataImport.id);
      if (index === -1) {
        setSelectedDataImport(null);
      } else {
        setSelectedDataImport({
          ...dataImports[index],
          importFunction: selectedDataImport.importFunction,
          importFunctionArgs: selectedDataImport.importFunctionArgs,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataImports]);

  return (
    <div className="flex flex-col">
      <div className="flex mb-2 p-1 space-x-1">
        <Input
          placeholder="Search Data Imports"
          prefix={<Icon name="search" />}
          value={keywords}
          onChange={(e) => setKeywords(e.currentTarget.value)}
        />
      </div>
      {filteredDataImports.map((dataImport) => (
        <DataImportListItem
          key={dataImport.id}
          dataImport={dataImport}
          onEditClick={() => {
            setSelectedDataImport(dataImport);
          }}
        />
      ))}
      {dataImports.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-gray-500">No data imported previously</p>
        </div>
      ) : filteredDataImports.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-gray-500">No data imports found</p>
        </div>
      ) : null}
    </div>
  );
};

export default DataImportList;
