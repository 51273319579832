import { AppEvents, PanelProps } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Dropdown, Menu, useTheme2 } from '@grafana/ui';
import dayjs from 'dayjs';
import { domMax, LazyMotion, MotionConfig } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { ChannelGroup, DataImport, SimpleOptions } from 'types';
import { POST } from '../client';
import ChannelGroupChannelToggleList from './Channel/ChannelGroupChannelToggleList';
import ChannelGroups from './ChannelGroups';
import DataflowAddDrawer from './Dataflow/DataflowAddDrawer';
import DataflowList from './Dataflow/DataflowList';
import DataImportEditDrawer from './DataImport/DataImportEditDrawer';
import DataImportList from './DataImport/DataImportList';
import FunctionAddDrawer from './Function/FunctionAddDrawer';
import FunctionList from './Function/FunctionList';
import RecordAddModal from './Record/RecordAddModal';
import RecordList from './Record/RecordList';
import UpdateBodyTheme from './utils/UpdateBodyTheme';
import ProjectList from './Project/ProjectList';
import ProjectAddModal from './Project/ProjectAddModal';
import UserList from './User/UserList';
import UserAddModal from './User/UserAddModal';
import { css, cx } from '@emotion/css';
import EventTriggerAddDrawer from './EventTrigger/EventTriggerAddDrawer';
import EventTriggerList from './EventTrigger/EventTriggerList';
import EventTable from './Event/EventTable';
import DataExport from './DataExport/DataExport';
import DerivativeAddDrawer from './Derivative/DerivativeAddDrawer';
import DerivativeList from './Derivative/DerivativeList';

export const appState = new BehaviorSubject({
  groupNameEditing: null as number | null,
  setGroupNameEditing: (id: number | null) => {
    appState.next({ ...appState.getValue(), groupNameEditing: id });
  },
});

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ width, height, options, onOptionsChange }) => {
  const { view, selectedChannelGroup } = options;
  const [drawerView, setDrawerView] = useState<SimpleOptions['view']>(view);
  const [reducedMotion, setReducedMotion] = useState<'user' | 'always'>('user');
  const [open, setOpen] = useState(false);
  const [selectedDataImport, setSelectedDataImport] = useState<DataImport | null>(null);
  const theme = useTheme2();

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const getDrawer = useCallback(() => {
    if (!open) {
      return null;
    }

    switch (drawerView) {
      case 'functions':
        return <FunctionAddDrawer onClose={onClose} />;
      case 'dataflows':
        return <DataflowAddDrawer onClose={onClose} />;
      case 'records':
        return <RecordAddModal onClose={onClose} />;
      case 'projects':
        return <ProjectAddModal onClose={onClose} />;
      case 'users':
        return <UserAddModal onClose={onClose} />;
      case 'eventTriggers':
        return <EventTriggerAddDrawer onClose={onClose} />;
      case 'derivatives':
        return <DerivativeAddDrawer onClose={onClose} />;
      default:
        return null;
    }
  }, [open, drawerView, onClose]);

  const handleAddClick = () => {
    // setDrawerView(view);
    setOpen(true);
  };

  const handleChannelGroupSelected = (channelGroup: ChannelGroup) => {
    onOptionsChange({
      ...options,
      selectedChannelGroup: channelGroup,
    });
  };

  useEffect(() => {
    // observe whether body has classname 'react-draggable-transparent-selection' and set reducedMotion accordingly
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'attributes') {
          if (mutation.attributeName === 'class') {
            setReducedMotion(
              document.body.classList.contains('react-draggable-transparent-selection') ? 'always' : 'user'
            );
          }
        }
      }
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <MotionConfig reducedMotion={reducedMotion}>
      <LazyMotion features={domMax}>
        <div className="relative max-h-full overflow-auto" style={{ width, height }}>
          {!selectedChannelGroup ? (
            <>
              <div
                className={cx(
                  'flex justify-between items-center p-1 pb-2 sticky top-0 z-10',
                  css({
                    backgroundColor: theme.colors.background.primary,
                  })
                )}
              >
                <p className="text-lg mb-0 font-semibold">
                  {view === 'channels'
                    ? 'Channel Manager'
                    : view === 'dataflows'
                    ? 'Live Streaming Data Manager'
                    : view === 'functions'
                    ? 'Function Manager'
                    : view === 'dataImports'
                    ? 'Imported Data Manager'
                    : view === 'records'
                    ? 'Record Data Manager'
                    : view === 'projects'
                    ? 'Project Manager'
                    : view === 'users'
                    ? 'User Manager'
                    : view === 'eventTriggers'
                    ? 'Event Trigger Manager'
                    : view === 'events'
                    ? 'Event Manager'
                    : view === 'dataExport'
                    ? 'Data Export'
                    : view === 'derivatives'
                    ? 'Derived Channel Manager'
                    : ''}
                </p>
                {/*<Select*/}
                {/*  value={view}*/}
                {/*  options={[*/}
                {/*    { label: 'Channels', value: 'channels' } as const,*/}
                {/*    { label: 'Dataflows', value: 'dataflows' } as const,*/}
                {/*    { label: 'Functions', value: 'functions' } as const,*/}
                {/*    { label: 'Data Imports', value: 'dataImports' } as const,*/}
                {/*    ...(user?.userRole === 'Admin' || user?.userRole === 'Developer'*/}
                {/*      ? [*/}
                {/*          { label: 'Files', value: 'files' } as const,*/}
                {/*          {*/}
                {/*            label: 'Records',*/}
                {/*            value: 'records',*/}
                {/*          } as const,*/}
                {/*        ]*/}
                {/*      : []),*/}
                {/*    ...(user?.userRole === 'Admin'*/}
                {/*      ? [*/}
                {/*          { label: 'Projects', value: 'projects' } as const,*/}
                {/*          {*/}
                {/*            label: 'Users',*/}
                {/*            value: 'users',*/}
                {/*          } as const,*/}
                {/*        ]*/}
                {/*      : []),*/}
                {/*  ]}*/}
                {/*  onChange={(item) => handleViewChange(item.value || 'channels')}*/}
                {/*  width="auto"*/}
                {/*/>*/}
                {view === 'channels' && (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          label="Add Channel"
                          onClick={() => {
                            setDrawerView('dataflows');
                            handleAddClick();
                          }}
                        />
                        <Menu.Item
                          label="Add Function"
                          onClick={() => {
                            setDrawerView('functions');
                            handleAddClick();
                          }}
                        />
                        <Menu.Item
                          label="Add Data Import"
                          onClick={async () => {
                            const response = await POST('/api/data-imports', {
                              body: {
                                name: `Data Import (${dayjs().format('YYYY-MM-DD HH:mm:ss')})`,
                              },
                            });
                            const appEvents = getAppEvents();

                            if (response.error) {
                              appEvents.publish({
                                type: AppEvents.alertError.name,
                                payload: [`Data Import add failed: ${response.error.message}`],
                              });
                              return;
                            }
                            setSelectedDataImport(response.data);
                          }}
                        />
                        <Menu.Item
                          label="Add Record"
                          onClick={() => {
                            setDrawerView('records');
                            handleAddClick();
                          }}
                        />
                        <Menu.Item
                          label="Add Project"
                          onClick={() => {
                            setDrawerView('projects');
                            handleAddClick();
                          }}
                        />
                        <Menu.Item
                          label="Add Derived Channel"
                          onClick={() => {
                            setDrawerView('derivatives');
                            handleAddClick();
                          }}
                        />
                      </Menu>
                    }
                  >
                    <Button icon="plus" fill="text">
                      Add
                    </Button>
                  </Dropdown>
                )}
                {view === 'dataflows' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add Dataflow
                  </Button>
                )}
                {view === 'functions' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add Function
                  </Button>
                )}
                {view === 'dataImports' && (
                  <Button
                    icon="plus"
                    fill="text"
                    onClick={async () => {
                      const response = await POST('/api/data-imports', {
                        body: {
                          name: `Data Import (${dayjs().format('YYYY-MM-DD HH:mm:ss')})`,
                        },
                      });
                      const appEvents = getAppEvents();

                      if (response.error) {
                        appEvents.publish({
                          type: AppEvents.alertError.name,
                          payload: [`Data Import add failed: ${response.error.message}`],
                        });
                        return;
                      }

                      setSelectedDataImport(response.data);
                    }}
                  >
                    Add Data Import
                  </Button>
                )}
                {view === 'records' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add Record
                  </Button>
                )}
                {view === 'projects' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add Project
                  </Button>
                )}
                {view === 'users' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add User
                  </Button>
                )}
                {view === 'eventTriggers' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add Event Trigger
                  </Button>
                )}
                {view === 'derivatives' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add Derived Channel
                  </Button>
                )}
              </div>
              <div className="mt-4">
                {view === 'channels' && (
                  <ChannelGroups
                    height={height}
                    reducedMotion={reducedMotion}
                    onChannelGroupSelected={handleChannelGroupSelected}
                  />
                )}
                {view === 'dataflows' && <DataflowList />}
                {view === 'functions' && <FunctionList />}
                {view === 'dataImports' && (
                  <DataImportList
                    selectedDataImport={selectedDataImport}
                    setSelectedDataImport={setSelectedDataImport}
                  />
                )}
                {view === 'records' && <RecordList />}
                {view === 'projects' && <ProjectList />}
                {view === 'users' && <UserList />}
                {view === 'eventTriggers' && <EventTriggerList />}
                {view === 'events' && <EventTable width={width} height={height} />}
                {view === 'dataExport' && <DataExport />}
                {view === 'derivatives' && <DerivativeList />}
              </div>
            </>
          ) : (
            <ChannelGroupChannelToggleList options={options} onOptionsChange={onOptionsChange} />
          )}
          {selectedDataImport && (
            <DataImportEditDrawer dataImport={selectedDataImport} onClose={() => setSelectedDataImport(null)} />
          )}
        </div>
        {getDrawer()}
        <UpdateBodyTheme />
      </LazyMotion>
    </MotionConfig>
  );
};
