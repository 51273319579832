import {
  Checkbox,
  Field,
  FieldSet,
  FormAPI,
  HorizontalGroup,
  Input,
  InputControl,
  RadioButtonGroup,
  ReactMonacoEditor,
  TextArea,
} from '@grafana/ui';
import React from 'react';
import { FunctionDataType, FunctionPinLevel, FunctionType } from '../../types';

export type FunctionFormValues = {
  name: string;
  description: string;
  schema: string;
  code: string;
  type: FunctionType;
  inputType: FunctionDataType;
  outputType: FunctionDataType;
  pinLevel: FunctionPinLevel;
};

interface FunctionFormProps extends FormAPI<FunctionFormValues> {
  hideFunctionType?: boolean;
}

const FunctionForm: React.FC<FunctionFormProps> = ({ register, errors, control, watch, hideFunctionType }) => {
  const type = watch('type');

  return (
    <FieldSet>
      {!hideFunctionType && (
        <Field label="Type" invalid={!!errors.type} error={errors.type?.message}>
          <InputControl
            control={control}
            name="type"
            render={({ field }) => (
              <RadioButtonGroup
                {...field}
                options={[
                  { label: 'Source', value: 'Source' },
                  { label: 'Process', value: 'Process' },
                  { label: 'Sink', value: 'Sink' },
                  { label: 'Import', value: 'Import' },
                  { label: 'Post Source', value: 'PostSource' },
                  { label: 'Event Trigger', value: 'EventTrigger' },
                  { label: 'Export', value: 'Export' },
                  { label: 'Derived', value: 'Derivative' },
                ]}
              />
            )}
          />
        </Field>
      )}
      {type !== 'Import' && type !== 'PostSource' && type !== 'EventTrigger' && type !== 'Export' && (
        <HorizontalGroup spacing="lg">
          <Field label="Input Type" invalid={!!errors.inputType} error={errors.inputType?.message}>
            <InputControl
              control={control}
              name="inputType"
              render={({ field }) => (
                <RadioButtonGroup
                  {...field}
                  options={[
                    { label: 'Timeseries', value: 'TIMESERIES' },
                    { label: 'Frequency', value: 'FREQUENCY' },
                  ]}
                  disabled={type === 'Source'}
                />
              )}
            />
          </Field>
          <Field label="Output Type" invalid={!!errors.outputType} error={errors.outputType?.message}>
            <InputControl
              control={control}
              name="outputType"
              render={({ field }) => (
                <RadioButtonGroup
                  {...field}
                  options={[
                    { label: 'Timeseries', value: 'TIMESERIES' },
                    { label: 'Frequency', value: 'FREQUENCY' },
                  ]}
                />
              )}
              disabled={type === 'Derivative'}
            />
          </Field>
        </HorizontalGroup>
      )}
      <Field label="Name" invalid={!!errors.name} error={errors.name?.message} required>
        <Input
          type="text"
          {...register('name', {
            required: 'Name is required',
          })}
        />
      </Field>
      <Field label="Description" invalid={!!errors.description} error={errors.description?.message}>
        <TextArea type="text" {...register('description')} />
      </Field>
      <Field label="Schema (JSON)" invalid={!!errors.schema} error={errors.schema?.message}>
        <InputControl
          control={control}
          name="schema"
          render={({ field }) => (
            <ReactMonacoEditor
              {...field}
              height={Math.max(field.value?.split('\n').length ?? 0, 5) * 19 + 12}
              language="json"
              options={{
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                scrollbar: {
                  alwaysConsumeMouseWheel: false,
                },
              }}
            />
          )}
        />
      </Field>
      <Field label="Code (Python)" invalid={!!errors.code} error={errors.code?.message} required>
        <InputControl
          control={control}
          name="code"
          rules={{
            required: 'Code is required',
          }}
          render={({ field }) => (
            <ReactMonacoEditor
              {...field}
              height={Math.max(field.value?.split('\n').length ?? 0, 5) * 19 + 12}
              language="python"
              options={{
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                scrollbar: {
                  alwaysConsumeMouseWheel: false,
                },
              }}
            />
          )}
        />
      </Field>
      <InputControl
        control={control}
        name="pinLevel"
        render={({ field }) => (
          <Checkbox
            name={field.name}
            onBlur={field.onBlur}
            label="Pin Function"
            description="pin function on top of the function list, so that it is easier to find"
            value={field.value === 'PINNED'}
            onChange={(e) => field.onChange(e.currentTarget.checked ? 'PINNED' : 'UNPINNED')}
          />
        )}
      />
    </FieldSet>
  );
};

export default FunctionForm;
