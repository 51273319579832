import React, { useEffect, useRef, useState } from 'react';
import useConfirm from '../../hooks/useConfirm';
import { Button, Drawer, Form, FormsOnSubmit } from '@grafana/ui';
import DerivativeForm, { DerivativeFormValues } from './DerivativeForm';
import { PUT } from '../../client';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { Derivative } from '../../types';
import UpdateIsDirty from '../utils/UpdateIsDirty';
import { AbortError } from 'fork-ts-checker-webpack-plugin/lib/utils/async/abort-error';
import { useHotkeys } from 'react-hotkeys-hook';
import { API_URL } from 'common';

interface DerivativeEditDrawerProps {
  derivative: Derivative;
  onClose: () => void;
}

const DerivativeEditDrawer: React.FC<DerivativeEditDrawerProps> = ({ derivative, onClose }) => {
  const [result, setResult] = useState<string>('');
  const [ConfirmModalComponent, showConfirm] = useConfirm({
    title: 'Unsaved Changes',
    body: 'You have unsaved changes, are you sure you want to discard it?',
    confirmText: 'Discard',
    onConfirm: onClose,
  });
  const [isRunning, setIsRunning] = useState(false);
  const isDirtyRef = useRef(false);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const controllerRef = useRef<AbortController | null>(null);
  const afterSubmitRef = useRef<(() => void) | null>(null);

  const handleSubmit: FormsOnSubmit<DerivativeFormValues> = async (data) => {
    try {
      if (!data.source || !data.sink) {
        return;
      }

      data.processes.forEach((process, index) => {
        process.sequence = index + 1;
      });

      const { error } = await PUT('/api/derivatives/{id}', {
        params: {
          path: {
            id: data.id,
          },
        },
        body: {
          name: data.name,
          source: data.source,
          sourceArgs: data.sourceArgs,
          processes: data.processes,
          sink: data.sink,
          sinkArgs: data.sinkArgs,
        },
      });

      const appEvents = getAppEvents();

      if (!error) {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Derivative edited successfully'],
        });
        // onClose();
        afterSubmitRef.current?.();
      } else {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Derivative edit failed: ${error.message}`],
        });
      }
    } catch (err) {
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Derivative edit failed: ${err}`],
      });
    } finally {
      afterSubmitRef.current = null;
    }
  };

  const handleRun = async (values: DerivativeFormValues) => {
    if (!values.source || !values.sink) {
      return;
    }

    setIsRunning(true);

    let firstMessage = true;

    controllerRef.current = new AbortController();

    try {
      setResult('Running...');

      values.processes.forEach((process, index) => {
        process.sequence = index + 1;
      });

      const response = await fetch(`${API_URL}/api/derivatives/${derivative.id}/run`, {
        method: 'POST',
        body: JSON.stringify({
          name: values.name,
          source: values.source,
          sourceArgs: values.sourceArgs,
          processes: values.processes,
          sink: values.sink,
          sinkArgs: values.sinkArgs,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        signal: controllerRef.current!.signal,
      });
      if (!response.ok) {
        setResult('Error occurred');
        return;
      }
      const reader = response.body?.getReader();
      if (!reader) {
        setResult('Error occurred');
        return;
      }
      let done = false;
      while (!done) {
        const { value, done: doneValue } = await reader.read();
        done = doneValue;
        if (value) {
          const text = new TextDecoder().decode(value);
          if (firstMessage) {
            setResult(text + '\n');
            firstMessage = false;
          } else {
            setResult((t) => t + text + '\n');
          }
        }
      }
      setResult((t) => t + 'Stopped');
    } catch (err) {
      if (err instanceof AbortError) {
        if (firstMessage) {
          setResult('Stopped');
        } else {
          setResult((t) => t + 'Stopped');
        }
      } else if (err instanceof Error) {
        if (firstMessage) {
          setResult('Stopped');
        } else {
          setResult((t) => t + 'Stopped');
        }
      } else {
        setResult(`${err}`);
      }
    } finally {
      setIsRunning(false);
    }
  };

  const handleClose = () => {
    if (!isDirtyRef.current) {
      onClose();
      return;
    }
    showConfirm();
  };

  useHotkeys(
    'ctrl+s,meta+s',
    (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      submitButtonRef.current?.click();
    },
    {
      enableOnFormTags: ['input', 'textarea'],
    }
  );

  useEffect(() => {
    return () => {
      controllerRef.current?.abort();
    };
  }, []);

  useEffect(() => {
    console.log('isRunning', isRunning);
  }, [isRunning]);

  return (
    <Drawer title="Edit Derived Channel" size="md" onClose={handleClose}>
      <>
        <Form
          defaultValues={{
            id: derivative.id,
            name: derivative.name,
            source: derivative.sourceId,
            sourceArgs: derivative.sourceArgs,
            processes: derivative.processes.map((process) => ({
              id: process.functionId.toString(),
              args: process.args,
              sequence: process.sequence,
              function: process.functionId,
            })),
            sink: derivative.sinkId,
            sinkArgs: derivative.sinkArgs,
          }}
          className="p-4"
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <UpdateIsDirty isDirtyRef={isDirtyRef} isDirty={props.formState.isDirty} />
              {/** @ts-ignore */}
              <DerivativeForm {...props} result={result} />
              <div className="space-x-3">
                <Button ref={submitButtonRef} type="submit">
                  Save
                </Button>
                {!isRunning ? (
                  <Button
                    key="run"
                    type="button"
                    onClick={async (e) => {
                      afterSubmitRef.current = async () => {
                        await handleRun(props.getValues());
                      };
                      submitButtonRef.current?.click();
                    }}
                    variant="success"
                  >
                    Save and Run
                  </Button>
                ) : (
                  <Button
                    key="stop"
                    type="button"
                    onClick={() => {
                      controllerRef.current?.abort();
                    }}
                    variant="destructive"
                  >
                    Stop
                  </Button>
                )}
              </div>
            </>
          )}
        </Form>
        {ConfirmModalComponent}
      </>
    </Drawer>
  );
};

export default DerivativeEditDrawer;
