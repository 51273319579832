import React, { useEffect, useRef, useState } from 'react';
import { Button, Drawer, Form, FormsOnSubmit } from '@grafana/ui';
import useConfirm from '../../hooks/useConfirm';
import UpdateIsDirty from '../utils/UpdateIsDirty';
import EventTriggerForm, { EventTriggerFormValues } from './EventTriggerForm';
import { POST } from '../../client';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { AbortError } from 'fork-ts-checker-webpack-plugin/lib/utils/async/abort-error';
import { API_URL } from 'common';

interface EventTriggerAddDrawerProps {
  onClose: () => void;
}

const EventTriggerAddDrawer: React.FC<EventTriggerAddDrawerProps> = ({ onClose }) => {
  const [testResult, setTestResult] = useState<string>('');
  const [ConfirmModalComponent, showConfirm] = useConfirm({
    title: 'Unsaved Changes',
    body: 'You have unsaved changes, are you sure you want to discard it?',
    confirmText: 'Discard',
    onConfirm: onClose,
  });
  const isDirtyRef = useRef(false);
  const [isRunning, setIsRunning] = useState(false);
  const controllerRef = useRef<AbortController | null>(null);

  const handleSubmit: FormsOnSubmit<EventTriggerFormValues> = async (data) => {
    try {
      if (!data.source || !data.sink) {
        return;
      }

      data.processes.forEach((process, index) => {
        process.sequence = index + 1;
      });

      const { error } = await POST('/api/event-triggers', {
        body: {
          name: data.name,
          source: data.source,
          sourceArgs: data.sourceArgs,
          processes: data.processes,
          sink: data.sink,
          sinkArgs: data.sinkArgs,
          threshold: data.threshold,
        },
      });

      const appEvents = getAppEvents();

      if (!error) {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Event trigger added successfully'],
        });
        onClose();
      } else {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Event trigger add failed: ${error.message}`],
        });
      }
    } catch (err) {
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Event trigger add failed: ${err}`],
      });
    }
  };

  const handleTest = async (values: EventTriggerFormValues) => {
    if (!values.source || !values.sink) {
      return;
    }

    setIsRunning(true);

    let firstMessage = true;

    controllerRef.current = new AbortController();

    // const t = setTimeout(() => {
    //   controllerRef.current?.abort();
    // }, 10000);

    try {
      setTestResult('Running...');

      values.processes.forEach((process, index) => {
        process.sequence = index + 1;
      });

      const response = await fetch(`${API_URL}/api/event-triggers/test`, {
        method: 'POST',
        body: JSON.stringify({
          name: values.name,
          source: values.source,
          sourceArgs: values.sourceArgs,
          processes: values.processes,
          sink: values.sink,
          sinkArgs: values.sinkArgs,
          threshold: values.threshold,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        signal: controllerRef.current!.signal,
      });
      if (!response.ok) {
        setTestResult('Error occurred');
        return;
      }
      const reader = response.body?.getReader();
      if (!reader) {
        setTestResult('Error occurred');
        return;
      }
      let done = false;
      while (!done) {
        const { value, done: doneValue } = await reader.read();
        done = doneValue;
        if (value) {
          const text = new TextDecoder().decode(value);
          if (firstMessage) {
            setTestResult(text + '\n');
            firstMessage = false;
          } else {
            setTestResult((t) => t + text + '\n');
          }
        }
      }
    } catch (err) {
      if (err instanceof AbortError) {
        if (firstMessage) {
          setTestResult('Stopped');
        } else {
          setTestResult((t) => t + 'Stopped');
        }
      } else if (err instanceof Error) {
        if (firstMessage) {
          setTestResult('Stopped');
        } else {
          setTestResult((t) => t + 'Stopped');
        }
      } else {
        setTestResult(`${err}`);
      }
    } finally {
      setIsRunning(false);
    }
  };

  const handleClose = () => {
    if (!isDirtyRef.current) {
      onClose();
      return;
    }
    showConfirm();
  };

  useEffect(() => {
    return () => {
      controllerRef.current?.abort();
    };
  }, []);

  return (
    <Drawer title="Add Event Trigger" size="md" onClose={handleClose} scrollableContent>
      <>
        <Form
          defaultValues={{
            name: '',
            source: null,
            sourceArgs: {},
            processes: [],
            sink: null,
            sinkArgs: {},
            threshold: 10,
          }}
          className="p-4"
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <UpdateIsDirty isDirtyRef={isDirtyRef} isDirty={props.formState.isDirty} />
              <EventTriggerForm {...props} testResult={testResult} />
              <div className="space-x-3">
                {!isRunning ? (
                  <Button
                    key="test"
                    type="button"
                    onClick={async (e) => {
                      await handleTest(props.getValues());
                    }}
                    variant="success"
                  >
                    Test
                  </Button>
                ) : (
                  <Button
                    key="stop"
                    type="button"
                    onClick={() => {
                      controllerRef.current?.abort();
                    }}
                    variant="destructive"
                  >
                    Stop
                  </Button>
                )}
                <Button type="submit">Add</Button>
              </div>
            </>
          )}
        </Form>
        {ConfirmModalComponent}
      </>
    </Drawer>
  );
};

export default EventTriggerAddDrawer;
