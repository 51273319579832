import React, { useEffect, useMemo, useState } from 'react';
import { FunctionPinLevel, FunctionType, type ProjectUserRole, TypedFunction } from 'types';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WS_API_URL } from 'common';
import FunctionListItem from './FunctionListItem';
import useObservable from '../../hooks/useObservable';
import { currentProjectIdObservable } from '../../observables';
import { Icon, Input, MultiSelect } from '@grafana/ui';

const FunctionList = () => {
  const [functions, setFunctions] = useState<TypedFunction[]>([]);
  const projectId = useObservable(currentProjectIdObservable, 0);
  const [keywords, setKeywords] = useState('');
  const [selectedTypes, setSelectedTypes] = useState<FunctionType[]>([]);

  const filteredFunctions = useMemo(() => {
    if (!keywords && selectedTypes.length === 0) {
      return functions;
    }
    return functions.filter((fn) => {
      return (
        (keywords === '' ||
          fn.function.name.toLowerCase().includes(keywords.toLowerCase()) ||
          fn.function.description?.toLowerCase().includes(keywords.toLowerCase())) &&
        (selectedTypes.length === 0 || selectedTypes.includes(fn.__typename as FunctionType))
      );
    });
  }, [functions, keywords, selectedTypes]);

  useEffect(() => {
    if (projectId === 0) {
      return;
    }

    const ws = new ReconnectingWebSocket(`${WS_API_URL}/api/typed-functions/watch?project_id=${projectId}`);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data) as TypedFunction[];
      setFunctions(
        data
          .filter((fn) => !fn.function.dataflowId && !fn.function.eventTriggerId && !fn.function.derivativeId)
          .sort((a, b) => {
            if (a.function.pinLevel !== b.function.pinLevel) {
              return getPinLevelIndex(b.function.pinLevel) - getPinLevelIndex(a.function.pinLevel);
            }
            return a.function.name.localeCompare(b.function.name);
          })
      );
    };

    return () => {
      ws.close();
    };
  }, [projectId]);

  return (
    <div className="flex flex-col">
      <div className="flex mb-2 p-1 space-x-1">
        <Input
          placeholder="Search Functions"
          prefix={<Icon name="search" />}
          value={keywords}
          onChange={(e) => setKeywords(e.currentTarget.value)}
        />
        <MultiSelect
          className="max-w-56"
          placeholder="Type"
          options={[
            {
              label: 'Source',
              value: 'Source',
            },
            {
              label: 'Process',
              value: 'Process',
            },
            {
              label: 'Sink',
              value: 'Sink',
            },
            {
              label: 'Post Source',
              value: 'PostSource',
            },
            {
              label: 'Import',
              value: 'Import',
            },
            {
              label: 'Export',
              value: 'Export',
            },
            {
              label: 'Event Trigger',
              value: 'EventTrigger',
            },
            {
              label: 'Derived',
              value: 'Derivative',
            },
          ]}
          value={selectedTypes}
          onChange={(v) => {
            setSelectedTypes(v.map((item) => item.value as FunctionType));
          }}
        />
      </div>
      {filteredFunctions.map((fn) => (
        <FunctionListItem key={fn.function.name} fn={fn} />
      ))}
    </div>
  );
};

const getPinLevelIndex = (pinLevel: FunctionPinLevel) => {
  return ['UNPINNED', 'PINNED', 'SYSTEM'].indexOf(pinLevel);
};

export default FunctionList;
