import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TypedFunction } from '../../types';
import {
  DropzoneFile,
  Field,
  FieldSet,
  FileListItem,
  FormAPI,
  Icon,
  InputControl,
  ReactMonacoEditor,
  useTheme2,
} from '@grafana/ui';
import { GET } from '../../client';
import DataflowFunctionBlock from '../Dataflow/DataflowFunctionBlock';
import DataflowInlineFunction, { DataflowInlineFunctionFormValues } from '../Dataflow/DataflowInlineFunction';
import DataflowNewFunction from '../Dataflow/DataflowNewFunction';
import useObservable from '../../hooks/useObservable';
import { currentProjectIdObservable } from '../../observables';
import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';

export type DataImportFormValues = {
  importFunction: number | { function: DataflowInlineFunctionFormValues };
  importFunctionArgs: any;
};

interface DataImportFormProps extends FormAPI<DataImportFormValues> {
  console: string;
  files: DropzoneFile[];
  setFiles: React.Dispatch<React.SetStateAction<DropzoneFile[]>>;
}

const DataImportForm: React.FC<DataImportFormProps> = ({
  register,
  errors,
  control,
  watch,
  getValues,
  setValue,
  console: consoleText,
  files,
  setFiles,
}) => {
  const theme = useTheme2();
  const [typedFunctions, setTypedFunctions] = useState<TypedFunction[]>([]);
  const importFunctions = useMemo(
    () => typedFunctions.filter((fn) => fn.__typename === 'Import'),
    [typedFunctions]
  ) as Array<Extract<TypedFunction, { __typename: 'Import' }>>;
  const importInited = useRef(false);
  const currentProjectId = useObservable(currentProjectIdObservable, 0);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles((files) => files.concat(acceptedFiles.map((file) => ({ file, id: v4(), error: null }))));
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (currentProjectId === 0) {
      return;
    }
    GET('/api/typed-functions')
      .then(({ data }) => setTypedFunctions(data || []))
      .catch(console.log);
  }, [currentProjectId]);

  return (
    <FieldSet className="mb-2">
      {/*<Field label="Name" invalid={!!errors.name} error={errors.name?.message} required>*/}
      {/*  <Input*/}
      {/*    type="text"*/}
      {/*    {...register('name', {*/}
      {/*      required: 'Name is required',*/}
      {/*    })}*/}
      {/*  />*/}
      {/*</Field>*/}
      <Field label="Files">
        {/*<div className="flex space-x-2 items-center">*/}
        {/*  <InputControl*/}
        {/*    control={control}*/}
        {/*    name="files"*/}
        {/*    render={({ field }) => (*/}
        {/*      <Select*/}
        {/*        {...field}*/}
        {/*        value={field.value}*/}
        {/*        isMulti*/}
        {/*        onChange={(value) => {*/}
        {/*          field.onChange((value as Array<SelectableValue<String>>).map((v) => v.value));*/}
        {/*        }}*/}
        {/*        options={files?.map((file) => ({ label: file.name, value: file.name })) ?? []}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*  /!*<Button type="button" size="md" onClick={() => setOpen(true)}>*!/*/}
        {/*  /!*  Upload*!/*/}
        {/*  /!*</Button>*!/*/}
        {/*</div>*/}
        <div className="border border-dashed border-gray-600 w-full rounded-md p-4">
          <div {...getRootProps()} className="relative cursor-pointer">
            <input {...getInputProps()} />
            <div className="flex items-center justify-center py-4">
              <div className="flex flex-col items-center space-y-2">
                <Icon name="upload" className="w-7 h-7" />
                <span className="font-medium text-center">Upload file</span>
              </div>
            </div>
            {isDragActive && (
              <div
                className="absolute inset-0 flex items-center justify-center"
                style={{ backgroundColor: theme.colors.background.secondary }}
              >
                <span className="font-medium text-center text-white">Drop the files here ...</span>
              </div>
            )}
          </div>
          {files.length > 0 && (
            <div className="pt-4">
              <p className="font-medium text-base mb-0.5">Files ({files.length})</p>
              <div className="flex flex-col space-y-2 max-h-96 overflow-y-auto">
                {files.map((file: DropzoneFile) => (
                  <FileListItem
                    key={file.id}
                    file={file}
                    removeFile={(file) => {
                      setFiles(files.filter((f) => f.id !== file.id));
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Field>
      <Field label="Import Function" invalid={!!errors.importFunction} error={errors.importFunction?.message} required>
        <InputControl
          control={control}
          name="importFunction"
          rules={{
            required: 'Import Function is required',
          }}
          render={({ field }) => {
            if (field.value === null) {
              return (
                <DataflowNewFunction
                  typedFunctions={importFunctions}
                  type="Import"
                  setValue={(value) => {
                    field.onChange(value);
                  }}
                />
              );
            }

            const importFunction = importFunctions.find((fn) => fn.function.id === field.value);

            // console.log(importFunction, field.value);
            if (typeof field.value !== 'number') {
              const handleSetValue = (value: DataflowInlineFunctionFormValues) => {
                field.onChange({
                  function: {
                    name: 'Inline Import',
                    description: '',
                    schema: {},
                    code: value.code,
                    pinLevel: 'UNPINNED',
                    createdAt: new Date().toISOString(),
                  },
                });
              };

              const value =
                typeof field.value !== 'number'
                  ? {
                      code: field.value.function.code,
                    }
                  : {
                      code: importFunction?.function.code || '',
                    };

              if (!importInited.current) {
                handleSetValue(value);
                importInited.current = true;
              }

              return (
                <DataflowInlineFunction
                  type="Import"
                  value={value}
                  setValue={(value) => {
                    field.onChange({
                      inputType: value.inputType,
                      outputType: value.outputType,
                      function: {
                        name: 'Inline Import',
                        description: '',
                        schema: {},
                        code: value.code,
                        pinLevel: 'UNPINNED',
                        createdAt: new Date().toISOString(),
                      },
                    });
                  }}
                  onDelete={() => field.onChange(null)}
                />
              );
            }
            return (
              <>
                {importFunctions.length > 0 && (
                  <DataflowFunctionBlock
                    fn={importFunctions.find((fn) => fn.function.id === field.value)!}
                    argument={getValues('importFunctionArgs')}
                    setArgument={(value) => {
                      // field2.value = value;
                      setValue('importFunctionArgs', value);
                    }}
                    onDelete={() => field.onChange(null)}
                  />
                )}
              </>
            );
          }}
        />
      </Field>
      {consoleText && (
        <div>
          <ReactMonacoEditor
            width="100%"
            height="100px"
            language="json"
            value={consoleText}
            options={{
              readOnly: true,
              lineNumbers: 'off',
              minimap: {
                enabled: false,
              },
            }}
          />
        </div>
      )}
    </FieldSet>
  );
};

export default DataImportForm;
