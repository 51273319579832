import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Drawer, Form, FormsOnSubmit, HorizontalGroup } from '@grafana/ui';
import React, { useRef } from 'react';
import { PUT } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import { TypedFunction } from '../../types';
import UpdateIsDirty from '../utils/UpdateIsDirty';
import FunctionForm, { FunctionFormValues } from './FunctionForm';

interface FunctionEditDrawerProps {
  fn: TypedFunction;
  onClose: () => void;
}

const FunctionEditDrawer: React.FC<FunctionEditDrawerProps> = ({ fn, onClose }) => {
  const [ConfirmModalComponent, showConfirm] = useConfirm({
    title: 'Unsaved Changes',
    body: 'You have unsaved changes, are you sure you want to discard it?',
    confirmText: 'Discard',
    onConfirm: onClose,
  });
  const isDirtyRef = useRef(false);

  const handleClose = () => {
    if (isDirtyRef.current) {
      showConfirm();
      return;
    }
    onClose();
  };

  const handleSubmit: FormsOnSubmit<FunctionFormValues> = async (data) => {
    try {
      if (data.pinLevel === 'SYSTEM') {
        return;
      }
      const path =
        fn.__typename === 'Source'
          ? ('source' as const)
          : data.type === 'Process'
          ? ('process' as const)
          : data.type === 'Sink'
          ? ('sink' as const)
          : data.type === 'Import'
          ? ('import' as const)
          : data.type === 'PostSource'
          ? ('post-source' as const)
          : data.type === 'EventTrigger'
          ? ('event-trigger' as const)
          : data.type === 'Export'
          ? ('export' as const)
          : ('derivative' as const);
      const { error } = await PUT(`/api/${path}-functions/{id}` as const, {
        body:
          fn.__typename === 'Source'
            ? {
                inputType: data.inputType,
                outputType: data.outputType,
                function: {
                  name: data.name,
                  description: data.description,
                  schema: JSON.parse(data.schema),
                  code: data.code,
                  pinLevel: data.pinLevel,
                },
              }
            : fn.__typename === 'Process'
            ? {
                inputType: data.inputType,
                outputType: data.outputType,
                function: {
                  name: data.name,
                  description: data.description,
                  schema: JSON.parse(data.schema),
                  code: data.code,
                  pinLevel: data.pinLevel,
                },
              }
            : fn.__typename === 'Sink'
            ? {
                inputType: data.inputType,
                outputType: data.outputType,
                function: {
                  name: data.name,
                  description: data.description,
                  schema: JSON.parse(data.schema),
                  code: data.code,
                  pinLevel: data.pinLevel,
                },
              }
            : fn.__typename === 'Derivative'
            ? {
                inputType: data.inputType,
                function: {
                  name: data.name,
                  description: data.description,
                  schema: JSON.parse(data.schema),
                  code: data.code,
                  pinLevel: data.pinLevel,
                },
              }
            : {
                function: {
                  name: data.name,
                  description: data.description,
                  schema: JSON.parse(data.schema),
                  code: data.code,
                  pinLevel: data.pinLevel,
                },
              },
        params: {
          path: {
            id: fn.function.id,
          },
        },
      });
      const appEvents = getAppEvents();
      if (!error) {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Function updated successfully'],
        });
        onClose();
      } else {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Function updated failed: ${error?.message ?? 'Server unreachable'}`],
        });
      }
    } catch (err) {
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Function updated failed: ${err}`],
      });
    }
  };

  return (
    <Drawer title="Edit Function" size="md" onClose={handleClose}>
      <>
        <Form
          onSubmit={handleSubmit}
          width="100%"
          maxWidth="none"
          className="pb-4"
          defaultValues={{
            type: fn.__typename,
            inputType:
              fn.__typename === 'Import' ||
              fn.__typename === 'PostSource' ||
              fn.__typename === 'EventTrigger' ||
              fn.__typename === 'Export'
                ? undefined
                : fn.__typename === 'Source'
                ? 'TIMESERIES'
                : fn.inputType,
            outputType:
              fn.__typename === 'Import' ||
              fn.__typename === 'PostSource' ||
              fn.__typename === 'EventTrigger' ||
              fn.__typename === 'Export' ||
              fn.__typename === 'Derivative'
                ? undefined
                : fn.outputType,
            name: fn.function.name,
            description: fn.function.description,
            schema: JSON.stringify(fn.function.schema, null, 4),
            code: fn.function.code,
            pinLevel: fn.function.pinLevel,
          }}
        >
          {(props) => (
            <>
              <UpdateIsDirty isDirtyRef={isDirtyRef} isDirty={props.formState.isDirty} />
              <FunctionForm {...props} hideFunctionType />
              <HorizontalGroup spacing="md">
                <Button type="submit">Save</Button>
              </HorizontalGroup>
            </>
          )}
        </Form>
        {ConfirmModalComponent}
      </>
    </Drawer>
  );
};

export default FunctionEditDrawer;
