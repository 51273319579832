import React, { useState } from 'react';
import { Button, Card } from '@grafana/ui';
import { Derivative } from '../../types';
import DerivativeEditDrawer from './DerivativeEditDrawer';
import { DELETE } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';

interface DerivativeListItemProps {
  derivative: Derivative;
}

const DerivativeListItem: React.FC<DerivativeListItemProps> = ({ derivative }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ConfirmComponent, handleDelete] = useConfirm({
    title: `Delete event trigger (${derivative.name})`,
    body: 'Are you sure you want to delete this event trigger?',
    confirmText: 'Delete',
    onConfirm: async () => {
      const appEvents = getAppEvents();
      try {
        const { error } = await DELETE('/api/derivatives/{id}', {
          params: {
            path: {
              id: derivative.id,
            },
          },
        });

        if (!error) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: [`Delete event trigger (${derivative.name}) Successfully`],
          });
        } else {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Delete event trigger (${derivative.name}) failed: ${error.message}`],
          });
        }
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete event trigger (${derivative.name}) failed: ${err}`],
        });
      }
    },
  });

  return (
    <Card>
      <Card.Heading>
        {derivative.name} {/*{derivative.projectId === activeProjectId ? (*/}
        {/*  <Card.Tags className="flex space-x-2 items-center">*/}
        {/*    {derivative.processes.length > 0 && <Badge text="Processed" color="orange" />}*/}
        {/*    {derivative.status.status === 'INITIALIZING' && <Badge text="Initializing" color="purple" />}*/}
        {/*    {derivative.status.status === 'RUNNING' && <Badge text="Running" color="green" />}*/}
        {/*    {derivative.status.status === 'STOPPED' && <Badge text="Stopped" color="purple" />}*/}
        {/*    {derivative.status.status === 'ERROR' && (*/}
        {/*      <div role="button" className="cursor-default">*/}
        {/*        <Badge text="Error" color="red" tooltip={derivative.status.message} />*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </Card.Tags>*/}
        {/*) : (*/}
        {/*  <Card.Tags className="flex space-x-2 items-center">*/}
        {/*    <Badge text="Inactive" color="red" tooltip="Only event trigger in active project can be started" />*/}
        {/*  </Card.Tags>*/}
        {/*)}*/}
      </Card.Heading>
      {/*<Card.Description>{derivative.description}</Card.Description>*/}
      {/* <Card.Meta>{[`Host: ${datasource.host}`, `Port: ${datasource.port}`]}</Card.Meta> */}

      <Card.Actions>
        <Button key="edit" variant="secondary" icon="edit" onClick={() => setIsOpen(true)}>
          Edit
        </Button>
        <Button key="delete" variant="destructive" icon="trash-alt" onClick={() => handleDelete()}>
          Delete
        </Button>
      </Card.Actions>
      {isOpen && <DerivativeEditDrawer derivative={derivative} onClose={() => setIsOpen(false)} />}
      {ConfirmComponent}
      {/* {isOpen && <DatasourceEditDrawer datasource={datasource} onClose={() => setIsOpen(false)} onEdited={onEdited} />} */}
    </Card>
  );
};

export default DerivativeListItem;
