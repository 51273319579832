import {
  Button,
  Field,
  FieldSet,
  Form,
  FormsOnSubmit,
  HorizontalGroup,
  Icon,
  InputControl,
  ReactMonacoEditor,
} from '@grafana/ui';
import DataflowNewFunction from '../Dataflow/DataflowNewFunction';
import DataflowFunctionBlock from '../Dataflow/DataflowFunctionBlock';
import React, { useEffect, useMemo, useState } from 'react';
import { currentProjectIdObservable } from '../../observables';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { API_URL } from 'common/src/main';
import { GET } from '../../client';
import { TypedFunction } from 'api';
import useObservable from '../../hooks/useObservable';

type DataExportFormValues = {
  functionId: number | null;
  functionArgs: any;
};

export default function DataExport() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [consoleText, setConsoleText] = React.useState<string>('');
  const abortController = React.useRef<AbortController | null>(null);
  const isLoadingRef = React.useRef(false);
  const [typedFunctions, setTypedFunctions] = useState<TypedFunction[]>([]);
  const exportFunctions = useMemo(
    () => typedFunctions.filter((fn) => fn.__typename === 'Export'),
    [typedFunctions]
  ) as Array<Extract<TypedFunction, { __typename: 'Export' }>>;
  const currentProjectId = useObservable(currentProjectIdObservable, 0);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    if (currentProjectId === 0) {
      return;
    }
    GET('/api/typed-functions')
      .then(({ data }) => setTypedFunctions(data || []))
      .catch(console.log);
  }, [currentProjectId]);

  const handleSubmit: FormsOnSubmit<DataExportFormValues> = async (data) => {
    try {
      setIsLoading(true);
      abortController.current?.abort();
      setConsoleText('');
      abortController.current = new AbortController();
      const response = await fetch(`${API_URL}/api/data-export`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'project-id': currentProjectIdObservable.value.toString(),
        },
        signal: abortController.current.signal,
      });
      const appEvents = getAppEvents();
      if (!response.ok) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Data export failed: ${response.statusText}`],
        });
        return;
      }
      const reader = response.body?.getReader();
      if (!reader) {
        return;
      }
      const decoder = new TextDecoder();
      let done = false;
      while (!done && isLoadingRef.current) {
        const { done: _done, value } = await reader.read();
        done = _done;
        const lines = decoder.decode(value).trim();
        for (const line of lines.split('\n')) {
          if (line.startsWith('download:')) {
            const id = line.split(':')[1].trim();
            const a = document.createElement('a');
            a.href = `${API_URL}/api/data-export/download?id=${id}`;
            a.download = 'export.zip';
            a.click();
            continue;
          }
          setConsoleText((prev) => prev + line + '\n');
        }
      }
      setIsLoading(false);
    } catch (err) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        return;
      }
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`DataImport edit failed: ${err}`],
      });
      setIsLoading(false);
    }
  };

  return (
    <Form
      defaultValues={{
        functionId: null,
        functionArgs: {},
      }}
      className="p-4"
      onSubmit={handleSubmit}
    >
      {({ control, errors, getValues, setValue }) => (
        <FieldSet className="mb-2">
          <Field label="Export Function" invalid={!!errors.functionId} error={errors.functionId?.message} required>
            <InputControl
              control={control}
              name="functionId"
              rules={{
                required: 'Export Function is required',
              }}
              render={({ field }) => {
                if (field.value === null) {
                  return (
                    <DataflowNewFunction
                      typedFunctions={exportFunctions}
                      type="Export"
                      setValue={(value) => {
                        field.onChange(value);
                      }}
                    />
                  );
                }

                return (
                  <>
                    {exportFunctions.length > 0 && (
                      <DataflowFunctionBlock
                        fn={exportFunctions.find((fn) => fn.function.id === field.value)!}
                        argument={getValues('functionArgs')}
                        setArgument={(value) => {
                          setValue('functionArgs', value);
                        }}
                        onDelete={() => field.onChange(null)}
                      />
                    )}
                  </>
                );
              }}
            />
          </Field>
          {consoleText && (
            <div>
              <ReactMonacoEditor
                width="100%"
                height="100px"
                language="json"
                value={consoleText}
                options={{
                  readOnly: true,
                  lineNumbers: 'off',
                  minimap: {
                    enabled: false,
                  },
                }}
              />
            </div>
          )}
          <HorizontalGroup spacing="md">
            <Button type="submit" disabled={isLoading}>
              {isLoading && <Icon name="fa fa-spinner" className="mr-2" />}
              {!isLoading ? 'Export' : 'Exporting...'}
            </Button>
            {isLoading && (
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  setIsLoading(false);
                  abortController.current?.abort();
                }}
              >
                Cancel
              </Button>
            )}
          </HorizontalGroup>
        </FieldSet>
      )}
    </Form>
  );
}
