import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TypedFunction } from '../../types';
import { Field, FieldSet, FormAPI, Input, InputControl, ReactMonacoEditor, TextArea } from '@grafana/ui';
import { GET } from '../../client';
import DataflowFunctionBlock from './DataflowFunctionBlock';
import DataflowInlineFunction, { DataflowInlineFunctionFormValues } from './DataflowInlineFunction';
import { components } from 'api';
import DataflowNewFunction from './DataflowNewFunction';
import { DragControls, Reorder, useDragControls } from 'framer-motion';
import _ from 'lodash';
import useObservable from '../../hooks/useObservable';
import { currentProjectIdObservable } from '../../observables';

export interface DataflowFormValues {
  description: string;
  /** Format: int64 */
  id: number;
  name: string;
  processes: Array<components['schemas']['DataflowProcessInput'] & { id: string }>;
  sink: components['schemas']['DataflowFunctionInput'] | null;
  sinkArgs: components['schemas']['JsonMap'];
  source: components['schemas']['DataflowFunctionInput'] | null;
  sourceArgs: components['schemas']['JsonMap'];
}

interface DataflowFormProps extends FormAPI<DataflowFormValues> {
  testResult: string;
}

const DataflowForm: React.FC<DataflowFormProps> = ({
  register,
  errors,
  control,
  watch,
  getValues,
  setValue,
  testResult,
}) => {
  const [typedFunctions, setTypedFunctions] = useState<TypedFunction[]>([]);
  const sourceFunctions = useMemo(
    () => typedFunctions.filter((fn) => fn.__typename === 'Source'),
    [typedFunctions]
  ) as Array<Extract<TypedFunction, { __typename: 'Source' }>>;
  const processFunctions = useMemo(
    () => typedFunctions.filter((fn) => fn.__typename === 'Process'),
    [typedFunctions]
  ) as Array<Extract<TypedFunction, { __typename: 'Process' }>>;
  const sinkFunctions = useMemo(
    () => typedFunctions.filter((fn) => fn.__typename === 'Sink'),
    [typedFunctions]
  ) as Array<Extract<TypedFunction, { __typename: 'Sink' }>>;
  const sourceInited = useRef(false);
  const processesInited = useRef<Record<string, boolean>>({});
  const sinkInited = useRef(false);
  const monacoRef = useRef<
    Parameters<Exclude<React.ComponentProps<typeof ReactMonacoEditor>['onMount'], undefined>>[0] | null
  >(null);
  const currentProjectId = useObservable(currentProjectIdObservable, 0);

  useEffect(() => {
    if (currentProjectId === 0) {
      return;
    }
    GET('/api/typed-functions')
      .then(({ data }) => setTypedFunctions(data || []))
      .catch(console.log);
  }, [currentProjectId]);

  useEffect(() => {
    if (!monacoRef.current) {
      return;
    }
    monacoRef.current.revealLine(monacoRef.current.getModel()!.getLineCount());
  }, [testResult]);

  return (
    <FieldSet>
      <Field label="Name" invalid={!!errors.name} error={errors.name?.message} required>
        <Input
          type="text"
          {...register('name', {
            required: 'Name is required',
          })}
        />
      </Field>
      <Field label="Description" invalid={!!errors.description} error={errors.description?.message}>
        <TextArea type="text" {...register('description')} />
      </Field>
      <Field label="Source" invalid={!!errors.source} error={errors.source?.message} required>
        <InputControl
          control={control}
          name="source"
          rules={{
            required: 'Source is required',
          }}
          render={({ field }) => {
            if (field.value === null) {
              return (
                <DataflowNewFunction
                  typedFunctions={sourceFunctions}
                  type="Source"
                  setValue={(value) => {
                    field.onChange(value);
                  }}
                />
              );
            }

            const sourceFunction = sourceFunctions.find((fn) => fn.function.id === field.value);

            if (sourceFunction?.function.dataflowId || typeof field.value !== 'number') {
              const handleSetValue = (value: DataflowInlineFunctionFormValues) => {
                field.onChange({
                  inputType: value.inputType,
                  outputType: value.outputType,
                  function: {
                    name: 'Inline Source',
                    description: '',
                    schema: {},
                    code: value.code,
                    pinLevel: 'UNPINNED',
                    createdAt: new Date().toISOString(),
                  },
                });
              };

              const value =
                typeof field.value !== 'number'
                  ? {
                      inputType: field.value.inputType,
                      outputType: field.value.outputType,
                      code: field.value.function.code,
                    }
                  : {
                      inputType: 'TIMESERIES' as const,
                      outputType: sourceFunction?.outputType || ('TIMESERIES' as const),
                      code: sourceFunction?.function.code || '',
                    };

              if (!sourceInited.current) {
                handleSetValue(value);
                sourceInited.current = true;
              }

              return (
                <DataflowInlineFunction
                  type="Source"
                  value={value}
                  setValue={(value) => {
                    field.onChange({
                      inputType: value.inputType,
                      outputType: value.outputType,
                      function: {
                        name: 'Inline Source',
                        description: '',
                        schema: {},
                        code: value.code,
                        pinLevel: 'UNPINNED',
                        createdAt: new Date().toISOString(),
                      },
                    });
                  }}
                  onDelete={() => field.onChange(null)}
                />
              );
            }
            return (
              <>
                {sourceFunctions.length > 0 && (
                  <DataflowFunctionBlock
                    fn={sourceFunctions.find((fn) => fn.function.id === field.value)!}
                    argument={getValues('sourceArgs')}
                    setArgument={(value) => {
                      // field2.value = value;
                      setValue('sourceArgs', value);
                    }}
                    onDelete={() => field.onChange(null)}
                  />
                )}
              </>
            );
          }}
        />
      </Field>
      <Field label="Processes">
        <InputControl
          control={control}
          name="processes"
          render={({ field }) => {
            const getFunction = (fn: (typeof field.value)[number]) => {
              if (typeof fn.function === 'number') {
                const p = processFunctions.find((f) => f.function.id === fn.function);
                if (p?.function.dataflowId) {
                  return {
                    inputType: 'TIMESERIES',
                    outputType: p.outputType,
                    code: p.function.code,
                  } as const;
                }
                return fn.function;
              } else {
                return {
                  inputType: fn.function.inputType,
                  outputType: fn.function.outputType,
                  code: fn.function.function.code,
                } as const;
              }
            };

            return (
              <div className="flex flex-col space-y-3">
                <Reorder.Group
                  values={field.value}
                  onReorder={field.onChange}
                  axis="y"
                  className="flex flex-col list-none space-y-3"
                >
                  {field.value.map((fn, index) => (
                    <ReorderProcessFunctionListItem
                      key={fn.id}
                      processFunctions={processFunctions}
                      fn={fn}
                      index={index}
                      getFunction={getFunction}
                      getValues={getValues}
                      field={field}
                      processesInited={processesInited}
                    />
                  ))}
                </Reorder.Group>
                <DataflowNewFunction
                  typedFunctions={processFunctions}
                  type="Process"
                  getValues={getValues}
                  setValue={(value) => {
                    const processes = getValues('processes');
                    field.onChange([...processes, { ...value, id: generateRandomString(10) }]);
                  }}
                />
              </div>
            );
          }}
        />
      </Field>
      <Field label="Sink" invalid={!!errors.sink} error={errors.sink?.message} required>
        <InputControl
          control={control}
          name="sink"
          rules={{
            required: 'Sink is required',
          }}
          render={({ field }) => {
            if (field.value === null) {
              return (
                <DataflowNewFunction
                  typedFunctions={sinkFunctions}
                  type="Sink"
                  setValue={(value) => {
                    field.onChange(value);
                  }}
                />
              );
            }

            const sinkFunction = sinkFunctions.find((fn) => fn.function.id === field.value);

            if (sinkFunction?.function.dataflowId || typeof field.value !== 'number') {
              const value =
                typeof field.value !== 'number'
                  ? {
                      inputType: field.value.inputType,
                      outputType: field.value.outputType,
                      code: field.value.function.code,
                    }
                  : {
                      inputType: 'TIMESERIES' as const,
                      outputType: sinkFunction?.outputType || ('TIMESERIES' as const),
                      code: sinkFunction?.function.code || '',
                    };

              const handleSetValue = (value: DataflowInlineFunctionFormValues) => {
                field.onChange({
                  inputType: value.inputType,
                  outputType: value.outputType,
                  function: {
                    name: 'Inline Sink',
                    description: '',
                    schema: {},
                    code: value.code,
                    pinLevel: 'UNPINNED',
                    createdAt: new Date().toISOString(),
                  },
                });
              };

              if (!sinkInited.current) {
                handleSetValue(value);
                sinkInited.current = true;
              }

              return (
                <DataflowInlineFunction
                  type="Sink"
                  value={value}
                  setValue={handleSetValue}
                  onDelete={() => field.onChange(null)}
                />
              );
            }
            return (
              <>
                {sinkFunctions.length > 0 && (
                  <DataflowFunctionBlock
                    fn={sinkFunctions.find((fn) => fn.function.id === field.value)!}
                    argument={getValues('sinkArgs')}
                    setArgument={(value) => {
                      setValue('sinkArgs', value);
                    }}
                    onDelete={() => field.onChange(null)}
                  />
                )}
              </>
            );
          }}
        />
      </Field>
      {testResult && (
        <div>
          <ReactMonacoEditor
            width="100%"
            height="100px"
            language="json"
            value={testResult}
            options={{
              readOnly: true,
              lineNumbers: 'off',
              minimap: {
                enabled: false,
              },
            }}
            onMount={(monaco) => {
              monacoRef.current = monaco;
            }}
          />
        </div>
      )}
    </FieldSet>
  );
};

type ProcessFunctionListItemProps = {
  processFunctions: TypedFunction[];
  fn: DataflowFormValues['processes'][number];
  index: number;
  getFunction: (fn: DataflowFormValues['processes'][number]) => DataflowInlineFunctionFormValues | number;
  getValues: FormAPI<DataflowFormValues>['getValues'];
  field: {
    value: DataflowFormValues['processes'];
    onChange: (value: DataflowFormValues['processes']) => void;
  };
  processesInited: React.MutableRefObject<Record<string, boolean>>;
};

const ReorderProcessFunctionListItem: React.FC<ProcessFunctionListItemProps> = (props) => {
  const controls = useDragControls();

  return (
    <Reorder.Item value={props.fn} dragListener={false} dragControls={controls}>
      <MemoizedProcessFunctionListItem {...props} controls={controls} />
    </Reorder.Item>
  );
};

const ProcessFunctionListItem: React.FC<ProcessFunctionListItemProps & { controls: DragControls }> = ({
  processFunctions,
  fn,
  index,
  getFunction,
  getValues,
  field,
  processesInited,
  controls,
}) => {
  const value = getFunction(fn);

  if (typeof value !== 'number') {
    const handleSetValue = (value: DataflowInlineFunctionFormValues) => {
      const processes = getValues('processes');
      const p = processes[index];
      if (typeof p.function === 'number') {
        const processFunction = processFunctions.find((f) => f.function.id === fn.function)!;
        if (processFunction.__typename !== 'Process') {
          return;
        }
        p.function = {
          ...processFunction,
          function: {
            ...processFunction.function,
            pinLevel: 'UNPINNED',
          },
        };
      }
      p.function.inputType = value.inputType!;
      p.function.outputType = value.outputType!;
      p.function.function.code = value.code;
      field.onChange([...processes.slice(0, index), p, ...processes.slice(index + 1)]);
    };

    if (!processesInited.current[fn.id]) {
      handleSetValue(value);
      processesInited.current[fn.id] = true;
    }

    return (
      <DataflowInlineFunction
        key={fn.id}
        type="Process"
        value={value}
        setValue={handleSetValue}
        onDelete={() => {
          const processes = getValues('processes');
          field.onChange([...processes.slice(0, index), ...processes.slice(index + 1)]);
        }}
        controls={controls}
      />
    );
  }

  const processFunction = processFunctions.find((f) => f.function.id === fn.function);

  if (!processFunction) {
    return null;
  }

  return (
    <DataflowFunctionBlock
      key={fn.id}
      fn={processFunction}
      argument={fn.args}
      setArgument={(value) => {
        const processes = getValues('processes');
        const p = processes[index];
        if (typeof p.function !== 'number') {
          return;
        }
        p.args = value;
        field.onChange([...processes.slice(0, index), p, ...processes.slice(index + 1)]);
      }}
      onDelete={() => {
        const processes = getValues('processes');
        field.onChange([...processes.slice(0, index), ...processes.slice(index + 1)]);
      }}
      controls={controls}
    />
  );
};

const MemoizedProcessFunctionListItem = React.memo(ProcessFunctionListItem, (prev, next) => {
  return _.isEqual(prev.processFunctions, next.processFunctions);
});

function generateRandomString(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default DataflowForm;
