export const API_URL =
  window.location.protocol === "https:"
    ? "https://tcoms-demo.elgo.app/server"
    : "/server";
export const WS_API_URL =
  window.location.protocol === "https:"
    ? "wss://tcoms-demo.elgo.app/server"
    : "/server";

export const WT_API_URL = "https://tcoms-demo.elgo.app:8443";
