import { Badge, Button, Icon, Input } from '@grafana/ui';
import { Popover } from '@headlessui/react';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { TypedFunction } from '../../types';

interface DataflowAddFromExistingFunctionButtonProps {
  typedFunctions: TypedFunction[];
  onSelected: (fn: TypedFunction) => void;
}

const DataflowAddFromExistingFunctionButton: React.FC<DataflowAddFromExistingFunctionButtonProps> = ({
  typedFunctions,
  onSelected,
}) => {
  const [keywords, setKeywords] = useState('');
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const search = (typedFunctions: TypedFunction[], keywords: string) => {
    if (keywords === '') {
      return typedFunctions;
    }

    return new Fuse(typedFunctions, {
      keys: ['function.name', 'function.description'],
      threshold: 0.3,
    })
      .search(keywords)
      .map((result) => result.item);
  };

  const filteredFunctions = useMemo(() => {
    return search(typedFunctions, keywords);
  }, [keywords, typedFunctions]);

  return (
    <Popover className="relative">
      <Popover.Button as="div" ref={setReferenceElement}>
        <Button>Existing function</Button>
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        className="absolute z-10 bg-gray-100 dark:bg-gray-800 shadow-md w-96 rounded-md overflow-hidden"
        style={styles.popper}
        {...attributes.popper}
      >
        {({ close }) => (
          <>
            <div className="p-2">
              <Input
                value={keywords}
                onChange={(event) => setKeywords(event.currentTarget.value)}
                placeholder="Search..."
                suffix={<Icon name="search" />}
              />
            </div>

            <ul className="divider list-none m-0 p-0">
              {typedFunctions.length > 0 ? (
                <>
                  {filteredFunctions.length > 0 ? (
                    filteredFunctions.map((fn) => (
                      <li
                        role="button"
                        key={fn.function.id}
                        className="p-4 text-base hover:bg-gray-200 dark:hover:bg-gray-700 flex"
                        onClick={() => {
                          onSelected(fn);
                          close();
                        }}
                      >
                        {fn.function.name}
                        {fn.__typename !== 'Import' &&
                          fn.__typename !== 'PostSource' &&
                          fn.__typename !== 'EventTrigger' &&
                          fn.__typename !== 'Export' && (
                            <>
                              <span className="inline-flex ml-auto mr-0 space-x-2">
                                {fn.__typename !== 'Source' ? (
                                  <Badge
                                    icon="arrow-up"
                                    text={fn.inputType}
                                    color={fn.inputType === 'TIMESERIES' ? 'blue' : 'orange'}
                                    className={
                                      fn.inputType === 'TIMESERIES'
                                        ? 'bg-slate-100 dark:bg-slate-900'
                                        : 'bg-yellow-100 dark:bg-yellow-900'
                                    }
                                  />
                                ) : (
                                  <Badge
                                    icon="arrow-up"
                                    text="NONE"
                                    color="red"
                                    className="bg-red-100 dark:bg-red-950"
                                  />
                                )}
                                {fn.__typename !== 'Derivative' ? (
                                  <Badge
                                    icon="arrow-down"
                                    text={fn.outputType}
                                    color={fn.outputType === 'TIMESERIES' ? 'blue' : 'orange'}
                                    className={
                                      fn.outputType === 'TIMESERIES'
                                        ? 'bg-slate-100 dark:bg-slate-900'
                                        : 'bg-yellow-100 dark:bg-yellow-900'
                                    }
                                  />
                                ) : (
                                  <Badge
                                    icon="arrow-down"
                                    text="NONE"
                                    color="red"
                                    className="bg-red-100 dark:bg-red-950"
                                  />
                                )}
                              </span>
                            </>
                          )}
                      </li>
                    ))
                  ) : (
                    <li key="no-functions-found" className="p-4 text-base">
                      No functions found
                    </li>
                  )}
                </>
              ) : (
                <li key="no-existing-functions" className="p-4 text-base">
                  No existing functions
                </li>
              )}
            </ul>
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default DataflowAddFromExistingFunctionButton;
