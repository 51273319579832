import { WS_API_URL } from 'common';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { APIEvent } from '../../types';
import dayjs from 'dayjs';
import { currentProjectIdObservable } from '../../observables';
import useObservable from '../../hooks/useObservable';
import {
  Button,
  DateTimePicker,
  Divider,
  InlineField,
  Input,
  Modal,
  MultiSelect,
  Table,
  TableCellDisplayMode,
} from '@grafana/ui';
import { AppEvents, DataFrame, DateTime, FieldType, dateTime } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { DELETE } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import { useMutationObserver } from '../../hooks/useMutationObserver';

type EventTableProps = {
  width: number;
  height: number;
};

const EventTable = ({ width, height }: EventTableProps) => {
  const [events, setEvents] = useState<APIEvent[]>([]);
  const projectId = useObservable(currentProjectIdObservable, 0);
  const [ConfirmComponent, handleDelete] = useConfirm<{ id: number }>({
    title: `Delete event`,
    body: 'Are you sure you want to delete this event?',
    confirmText: 'Delete',
    onConfirm: async (props) => {
      if (!props) {
        return;
      }
      const { id } = props;
      const appEvents = getAppEvents();
      try {
        const { error } = await DELETE('/api/events/{id}', {
          params: {
            path: {
              id,
            },
          },
        });
        if (error) {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Delete event failed: ${error.message}`],
          });
          return;
        }
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: [`Delete event Successfully`],
        });
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete event failed: ${err}`],
        });
      }
    },
  });
  const eventTypes = useMemo(() => {
    const types = new Set<string>();
    events.forEach((event) => {
      types.add(event.type);
    });
    return Array.from(types);
  }, [events]);
  const [keyword, setKeyword] = useState('');
  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);
  const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false);

  const filteredEvents = useMemo(() => {
    return events.filter((event) => {
      if (keyword && !event.name.includes(keyword)) {
        return false;
      }
      if (selectedEventTypes.length > 0 && !selectedEventTypes.includes(event.type)) {
        return false;
      }
      return true;
    });
  }, [events, keyword, selectedEventTypes]);

  const data = useMemo(
    () =>
      ({
        name: 'Events',
        fields: [
          {
            name: 'id',
            type: FieldType.number,
            config: {
              displayName: 'ID',
            },
            display: (a: APIEvent, b) => {
              return {
                text: a.id,
              };
            },
            values: filteredEvents,
          },
          {
            name: 'name',
            type: FieldType.string,
            config: {
              displayName: 'Name',
            },
            display: (a: APIEvent, b) => {
              return {
                text: a.name + (a.duplicateId > 1 ? ` (${a.duplicateId})` : ''),
              };
            },
            values: filteredEvents,
          },
          {
            name: 'type',
            type: FieldType.string,
            config: {
              displayName: 'Type',
            },
            display: (a: APIEvent, b) => {
              return {
                text: a.type,
              };
            },
            values: filteredEvents,
          },
          {
            name: 'createdAt',
            type: FieldType.time,
            config: {
              displayName: 'Created At',
            },
            display: (a: APIEvent, b) => {
              return {
                text: dayjs(a.createdAt).utc(true).local().format('YYYY-MM-DD HH:mm:ss'),
              };
            },
            values: filteredEvents,
          },
          {
            name: 'triggeredBy',
            type: FieldType.string,
            config: {
              displayName: 'Triggered By',
            },
            display: (a: APIEvent, b) => {
              return {
                text: a.triggeredBy.join(', '),
              };
            },
            values: filteredEvents,
          },
          {
            name: 'metadata',
            type: FieldType.string,
            config: {
              displayName: 'Metadata',
            },
            display: (a: APIEvent, b) => {
              return {
                text: JSON.stringify(a.metadata, null, 2),
              };
            },
            values: filteredEvents,
          },
          {
            name: 'Actions',
            type: FieldType.other,
            config: {
              decimals: 0,
              custom: {
                cellOptions: {
                  type: TableCellDisplayMode.Custom,
                  cellComponent: (props: any) => {
                    return (
                      <Button
                        variant="destructive"
                        icon="trash-alt"
                        onClick={() => {
                          const event = props.frame.fields[0].values[props.rowIndex] as APIEvent;
                          handleDelete({ confirmProps: { id: event.id } });
                        }}
                      >
                        Delete
                      </Button>
                    );
                  },
                },
              },
            },
            display: () => ({
              text: '',
              numeric: 0,
            }),
            values: [],
          },
        ],
        length: filteredEvents.length,
      } as DataFrame),
    [filteredEvents, handleDelete]
  );

  useEffect(() => {
    if (projectId === 0) {
      return;
    }

    const ws = new ReconnectingWebSocket(WS_API_URL + '/api/events/watch?project_id=' + projectId);

    ws.onmessage = (event) => {
      const events = JSON.parse(event.data) as APIEvent[];
      setEvents(events.sort((a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1)));
    };

    return () => {
      ws.close();
    };
  }, [projectId]);

  return (
    <>
      <div className="flex px-2 mb-2">
        <Input
          type="text"
          placeholder="Search Events by Name"
          value={keyword}
          className="max-w-64 mr-2"
          onChange={(e) => setKeyword(e.currentTarget.value)}
        />
        <MultiSelect
          placeholder="Type"
          options={eventTypes.map((v) => ({ label: v, value: v }))}
          value={selectedEventTypes.map((v) => ({ label: v, value: v }))}
          onChange={(e) => {
            setSelectedEventTypes(e.map((item) => item.value as string));
          }}
          className="max-w-48 mr-2"
        />
        <Button
          icon="trash-alt"
          variant="destructive"
          className="ml-auto mr-0"
          onClick={() => {
            setBulkDeleteOpen(true);
          }}
        >
          Bulk Delete
        </Button>
        <BulkDeleteEventModal
          open={bulkDeleteOpen}
          setOpen={setBulkDeleteOpen}
          events={events}
          eventTypes={eventTypes}
          filterKeyword={keyword}
          filterSelectedEventTypes={selectedEventTypes}
        />
      </div>
      <Table
        data={data}
        width={width}
        height={height - 100}
        enablePagination={data.length > 10}
        initialSortBy={[
          {
            displayName: 'Created At',
            desc: true,
          },
        ]}
      />
      {ConfirmComponent}
    </>
  );
};

function BulkDeleteEventModal({
  open,
  setOpen,
  events,
  eventTypes,
  filterKeyword,
  filterSelectedEventTypes,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  events: APIEvent[];
  eventTypes: string[];
  filterKeyword: string;
  filterSelectedEventTypes: string[];
}) {
  const [keyword, setKeyword] = useState(filterKeyword);
  const [startDate, setStartDate] = useState<DateTime | undefined>(dateTime(0) as DateTime);
  const [endDate, setEndDate] = useState<DateTime | undefined>(dateTime() as DateTime);
  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>(filterSelectedEventTypes);
  const [isLoading, setIsLoading] = useState(false);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);

  useMutationObserver(rootRef, () => {
    if (rootRef.current) {
      if (rootRef.current.clientWidth === width) {
        return;
      }
      setWidth(rootRef.current.clientWidth);
    }
  });

  const filteredEvents = useMemo(() => {
    return events.filter((event) => {
      if (keyword && !event.name.includes(keyword)) {
        return false;
      }
      if (selectedEventTypes.length > 0 && !selectedEventTypes.includes(event.type)) {
        return false;
      }
      if (startDate && dateTime(event.createdAt).isBefore(startDate)) {
        return false;
      }
      if (endDate && endDate.isBefore(dateTime(event.createdAt))) {
        return false;
      }
      return true;
    });
  }, [events, keyword, startDate, endDate, selectedEventTypes]);

  const [ConfirmComponent, handleDelete] = useConfirm<{ ids: number[] }>({
    title: `Bulk Delete events`,
    body: 'Are you sure you want to delete these events?',
    confirmText: 'Delete',
    onConfirm: async (props) => {
      if (!props) {
        return;
      }
      const { ids } = props;
      const appEvents = getAppEvents();
      try {
        setIsLoading(true);
        const { error } = await DELETE('/api/events', {
          body: {
            ids,
          },
        });
        if (error) {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Delete events failed: ${error.message}`],
          });
          return;
        }
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: [`Delete events Successfully`],
        });
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete events failed: ${err}`],
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    setSelectedEventTypes(filterSelectedEventTypes);
  }, [filterSelectedEventTypes, setSelectedEventTypes]);

  useEffect(() => {
    setKeyword(filterKeyword);
  }, [filterKeyword, setKeyword]);

  return (
    <Modal title="Bulk Delete Events" isOpen={open} onDismiss={() => setOpen(false)}>
      <div ref={rootRef} className="min-h-96 w-full">
        <div className="gap-2 flex flex-col">
          <InlineField label="Name" className="mb-0">
            <Input
              type="text"
              placeholder="Search"
              value={keyword}
              onChange={(e) => setKeyword(e.currentTarget.value)}
            />
          </InlineField>
          <div className="flex items-center space-x-2">
            <DateTimePicker
              onChange={(date) => {
                setStartDate(date);
                if (endDate && date && endDate.isBefore(date)) {
                  setEndDate(date);
                }
              }}
              date={startDate}
              label="From"
            />
            <DateTimePicker
              onChange={(date) => {
                setEndDate(date);
                if (startDate && date && date.isBefore(startDate)) {
                  setStartDate(date);
                }
              }}
              date={endDate}
              label="To"
            />
          </div>
          <InlineField label="Type" className="mb-0">
            <MultiSelect
              options={eventTypes.map((v) => ({ label: v, value: v }))}
              value={selectedEventTypes}
              onChange={(e) => setSelectedEventTypes(e.map((item) => item.value as string))}
            />
          </InlineField>
        </div>
        <Divider direction="horizontal" />
        <p className="text-lg">Target Events</p>
        <div className="border border-solid border-gray-200 dark:border-gray-700 rounded-sm">
          <Table
            data={
              {
                name: 'Events',
                fields: [
                  {
                    name: 'id',
                    type: FieldType.number,
                    config: {
                      displayName: 'ID',
                    },
                    display: (a: APIEvent, b) => {
                      return {
                        text: a.id,
                      };
                    },
                    values: filteredEvents,
                  },
                  {
                    name: 'name',
                    type: FieldType.string,
                    config: {
                      displayName: 'Name',
                    },
                    display: (a: APIEvent, b) => {
                      return {
                        text: a.name + (a.duplicateId > 1 ? ` (${a.duplicateId})` : ''),
                      };
                    },
                    values: filteredEvents,
                  },
                  {
                    name: 'type',
                    type: FieldType.string,
                    config: {
                      displayName: 'Type',
                    },
                    display: (a: APIEvent, b) => {
                      return {
                        text: a.type,
                      };
                    },
                    values: filteredEvents,
                  },
                  {
                    name: 'createdAt',
                    type: FieldType.time,
                    config: {
                      displayName: 'Created At',
                    },
                    display: (a: APIEvent, b) => {
                      return {
                        text: dayjs(a.createdAt).utc(true).local().format('YYYY-MM-DD HH:mm:ss'),
                      };
                    },
                    values: filteredEvents,
                  },
                  {
                    name: 'triggeredBy',
                    type: FieldType.string,
                    config: {
                      displayName: 'Triggered By',
                    },
                    display: (a: APIEvent, b) => {
                      return {
                        text: a.triggeredBy.join(', '),
                      };
                    },
                    values: filteredEvents,
                  },
                  {
                    name: 'metadata',
                    type: FieldType.string,
                    config: {
                      displayName: 'Metadata',
                    },
                    display: (a: APIEvent, b) => {
                      return {
                        text: JSON.stringify(a.metadata, null, 2),
                      };
                    },
                    values: filteredEvents,
                  },
                ],
                length: filteredEvents.length,
              } as DataFrame
            }
            enablePagination={filteredEvents.length > 0}
            width={width - 2}
            height={window.innerHeight * 0.7 - 300}
            initialSortBy={[
              {
                displayName: 'Created At',
                desc: true,
              },
            ]}
          />
        </div>
      </div>
      <Modal.ButtonRow>
        <Button
          variant="secondary"
          fill="outline"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="destructive"
          icon={isLoading ? 'spinner' : undefined}
          disabled={filteredEvents.length === 0 || isLoading}
          onClick={() => handleDelete({ confirmProps: { ids: filteredEvents.map((e) => e.id) } })}
        >
          {isLoading
            ? 'Deleting...'
            : filteredEvents.length === 0
            ? 'No Target Events'
            : `Delete ${filteredEvents.length} Event${filteredEvents.length > 1 ? 's' : ''}`}
        </Button>
        {ConfirmComponent}
      </Modal.ButtonRow>
    </Modal>
  );
}

export default EventTable;
