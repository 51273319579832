import React, { useState } from 'react';
import { Badge, Button, Card } from '@grafana/ui';
import { EventTrigger } from '../../types';
import EventTriggerEditDrawer from './EventTriggerEditDrawer';
import { DELETE, POST } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import Icons from '../Icons';
import useObservable from '../../hooks/useObservable';
import { runtimeStateObservable } from '../../observables';

interface EventTriggerListItemProps {
  eventTrigger: EventTrigger;
}

const EventTriggerListItem: React.FC<EventTriggerListItemProps> = ({ eventTrigger }) => {
  const activeProjectId = useObservable(runtimeStateObservable, {
    recording: null,
    activeProjectId: 0,
  }).activeProjectId;
  const [isOpen, setIsOpen] = useState(false);
  const [ConfirmComponent, handleDelete] = useConfirm({
    title: `Delete event trigger (${eventTrigger.name})`,
    body: 'Are you sure you want to delete this event trigger?',
    confirmText: 'Delete',
    onConfirm: async () => {
      const appEvents = getAppEvents();
      try {
        const { error } = await DELETE('/api/event-triggers/{id}', {
          params: {
            path: {
              id: eventTrigger.id,
            },
          },
        });

        if (!error) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: [`Delete event trigger (${eventTrigger.name}) Successfully`],
          });
        } else {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Delete event trigger (${eventTrigger.name}) failed: ${error.message}`],
          });
        }
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete event trigger (${eventTrigger.name}) failed: ${err}`],
        });
      }
    },
  });

  return (
    <Card>
      <Card.Heading>
        {eventTrigger.name}{' '}
        {eventTrigger.projectId === activeProjectId ? (
          <Card.Tags className="flex space-x-2 items-center">
            {eventTrigger.processes.length > 0 && <Badge text="Processed" color="orange" />}
            {eventTrigger.status.status === 'INITIALIZING' && <Badge text="Initializing" color="purple" />}
            {eventTrigger.status.status === 'RUNNING' && <Badge text="Running" color="green" />}
            {eventTrigger.status.status === 'STOPPED' && <Badge text="Stopped" color="purple" />}
            {eventTrigger.status.status === 'ERROR' && (
              <div role="button" className="cursor-default">
                <Badge text="Error" color="red" tooltip={eventTrigger.status.message} />
              </div>
            )}
          </Card.Tags>
        ) : (
          <Card.Tags className="flex space-x-2 items-center">
            <Badge text="Inactive" color="red" tooltip="Only event trigger in active project can be started" />
          </Card.Tags>
        )}
      </Card.Heading>
      {/*<Card.Description>{eventTrigger.description}</Card.Description>*/}
      {/* <Card.Meta>{[`Host: ${datasource.host}`, `Port: ${datasource.port}`]}</Card.Meta> */}

      <Card.Actions>
        {activeProjectId === eventTrigger.projectId && (
          <>
            <Button
              key="restart"
              variant="secondary"
              className="pl-4"
              onClick={async () => {
                try {
                  const response = await POST('/api/event-triggers/{id}/restart', {
                    params: {
                      path: {
                        id: eventTrigger.id,
                      },
                    },
                  });
                  const appEvents = getAppEvents();
                  if (response.error) {
                    appEvents.publish({
                      type: AppEvents.alertError.name,
                      payload: [`Restart event trigger (${eventTrigger.name}) failed`],
                    });
                    return;
                  }
                  appEvents.publish({
                    type: AppEvents.alertSuccess.name,
                    payload: [`Restart event trigger (${eventTrigger.name}) successfully`],
                  });
                } catch (e) {
                  const appEvents = getAppEvents();
                  appEvents.publish({
                    type: AppEvents.alertError.name,
                    payload: [`Restart event trigger (${eventTrigger.name}) failed`],
                  });
                }
              }}
            >
              <Icons.restart className="h-4 w-4 mr-2" />
              Restart
            </Button>
            {eventTrigger.status.status === 'RUNNING' && (
              <Button
                icon="times"
                key="stop"
                variant="secondary"
                onClick={async () => {
                  try {
                    const response = await POST('/api/event-triggers/{id}/stop', {
                      params: {
                        path: {
                          id: eventTrigger.id,
                        },
                      },
                    });
                    const appEvents = getAppEvents();
                    if (response.error) {
                      appEvents.publish({
                        type: AppEvents.alertError.name,
                        payload: [`Stop event trigger (${eventTrigger.name}) failed`],
                      });
                      return;
                    }
                    appEvents.publish({
                      type: AppEvents.alertSuccess.name,
                      payload: [`Stop event trigger (${eventTrigger.name}) successfully`],
                    });
                  } catch (e) {
                    const appEvents = getAppEvents();
                    appEvents.publish({
                      type: AppEvents.alertError.name,
                      payload: [`Stop event trigger (${eventTrigger.name}) failed`],
                    });
                  }
                }}
              >
                Stop
              </Button>
            )}
          </>
        )}
        <Button key="edit" variant="secondary" icon="edit" onClick={() => setIsOpen(true)}>
          Edit
        </Button>
        <Button key="delete" variant="destructive" icon="trash-alt" onClick={() => handleDelete()}>
          Delete
        </Button>
      </Card.Actions>
      {isOpen && <EventTriggerEditDrawer eventTrigger={eventTrigger} onClose={() => setIsOpen(false)} />}
      {ConfirmComponent}
      {/* {isOpen && <DatasourceEditDrawer datasource={datasource} onClose={() => setIsOpen(false)} onEdited={onEdited} />} */}
    </Card>
  );
};

export default EventTriggerListItem;
