import { Channel } from './types';

export function transformLocalName(name: string, channel?: Channel): string {
  if (name.startsWith('__u')) {
    // if ''
    // regex match __u{number}i{number}__{name}
    if (name.match(/__u\d+i\d+__/)) {
      return (
        name.split('__')[2] +
        ' (User)' +
        (channel ? ` (${channel.import?.name})` : ` (Import ${name.split('__')[1].slice(1)})`)
      );
    }
    return name.split('__')[2] + ' (User)';
  }
  if (name.startsWith('__e')) {
    return name.split('__')[2] + ` (Event ${name.split('__')[1].slice(1)})`;
  }
  if (name.startsWith('__r')) {
    return (
      name.split('__')[2] + (channel ? ` (${channel.record?.testId})` : ` (Record ${name.split('__')[1].slice(1)})`)
    );
  }
  if (name.startsWith('__i')) {
    return name.split('__')[2] + (channel ? ` (${channel.import?.name})` : ` (Import ${name.split('__')[1].slice(1)})`);
  }
  return name;
}
