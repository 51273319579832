import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Modal, Tab, TabsBar } from '@grafana/ui';
import { POST } from 'client';
import React, { useCallback } from 'react';
import UserForm, { UserFormValues } from './UserForm';
import { currentProjectIdObservable } from '../../observables';
import UserAddFromExisting from './UserAddFromExisting';
import { ProjectUserRole } from '../../types';

export default function UserAddModal({ onClose }: { onClose: () => void }) {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleCreate = useCallback(
    async (values: UserFormValues | { userId: number; role: ProjectUserRole }) => {
      const appEvents = getAppEvents();
      try {
        const response = await POST('/api/projects/{project_id}/users', {
          body: values,
          params: {
            path: {
              project_id: currentProjectIdObservable.value,
            },
          },
        });
        if (response.error) {
          throw new Error(response.error.message);
        }
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['User created successfully'],
        });
        onClose();
      } catch (e) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Failed to create user: ${e instanceof Error ? e.message : e}`],
        });
      }
    },
    [onClose]
  );

  return (
    <Modal title="Add User to Current Project" isOpen={true} onDismiss={onClose}>
      <TabsBar className="mb-6">
        <Tab label="New User" active={tabIndex === 0} onChangeTab={() => setTabIndex(0)} />
        <Tab label="Existing User" active={tabIndex === 1} onChangeTab={() => setTabIndex(1)} />
      </TabsBar>
      {tabIndex === 0 && (
        <UserForm
          onSubmit={handleCreate}
          actions={
            <Modal.ButtonRow>
              <Button variant="secondary" fill="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </Modal.ButtonRow>
          }
        />
      )}
      {tabIndex === 1 && <UserAddFromExisting onSubmit={handleCreate} onClose={onClose} />}
    </Modal>
  );
}
