import { Badge, Button, Card } from '@grafana/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { ProjectUser } from '../../types';
import UserDeleteModal from './UserDeleteModal';

dayjs.extend(utc);

interface UserListItemProps {
  user: ProjectUser;
  isMe: boolean;
  onEditClick: () => void;
}

const UserListItem: React.FC<UserListItemProps> = ({ user, isMe, onEditClick }) => {
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  return (
    <Card>
      <Card.Figure>
        <img src={user.avatarUrl} height="40" width="40" className="rounded-full" alt={user.name || user.login} />
      </Card.Figure>
      <Card.Heading>
        <div className="flex space-x-2">
          {/*<Avatar src={user.avatarUrl} alt={user.name || user.login} />*/}
          <p className="m-0">{user.name || user.login || user.email}</p>
        </div>
      </Card.Heading>
      <Card.Meta>
        <p className="m-0">{user.userRole}</p>
        {user.lastSeenAtAge && <p className="m-0">Last seen at {user.lastSeenAtAge}</p>}
      </Card.Meta>
      <Card.Tags>
        {!user.isDisabled ? <Badge text="Enabled" color="green" /> : <Badge text="Disabled" color="red" />}
      </Card.Tags>
      <Card.Actions>
        <Button key="edit" variant="secondary" icon="edit" onClick={onEditClick}>
          Edit
        </Button>
        {!isMe && (
          <Button key="delete" variant="destructive" icon="trash-alt" onClick={() => setDeleteOpen(true)}>
            Delete
          </Button>
        )}
        {/*{!user.active ? (*/}
        {/*  <Button key="finish" variant="success" icon="power" onClick={() => handleActivate()}>*/}
        {/*    Activate*/}
        {/*  </Button>*/}
        {/*) : (*/}
        {/*  <Button key="delete" variant="destructive" icon="trash-alt" onClick={() => handleDelete()}>*/}
        {/*    Delete*/}
        {/*  </Button>*/}
        {/*)}*/}
      </Card.Actions>
      {deleteOpen && <UserDeleteModal user={user} onClose={() => setDeleteOpen(false)} />}
      {/*{ConfirmActivateComponent}*/}
      {/* {isOpen && <DatasourceEditDrawer datasource={datasource} onClose={() => setIsOpen(false)} onEdited={onEdited} />} */}
    </Card>
  );
};

export default UserListItem;
