import React, { useMemo } from 'react';
import { Badge, Button, Card } from '@grafana/ui';
import { DataImport, DataImportResult } from '../../types';
import { DELETE } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import dayjs from 'dayjs';

interface DataflowListItemProps {
  dataImport: DataImport;
  onEditClick: () => void;
}

const DataflowListItem: React.FC<DataflowListItemProps> = ({ dataImport, onEditClick }) => {
  const [ConfirmComponent, handleDelete] = useConfirm({
    title: `Delete data import (${dataImport.name})`,
    body: 'Are you sure you want to delete this data import? All previous imported data will be removed.',
    confirmText: 'Delete',
    onConfirm: async () => {
      const appEvents = getAppEvents();
      try {
        const { error } = await DELETE('/api/data-imports/{id}', {
          params: {
            path: {
              id: dataImport.id,
            },
          },
        });

        if (!error) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: [`Delete data import (${dataImport.name}) Successfully`],
          });
        } else {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Delete data import (${dataImport.name}) failed: ${error.message}`],
          });
        }
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete import (${dataImport.name}) failed: ${err}`],
        });
      }
    },
  });
  const result = useMemo(() => {
    return dataImport.result as undefined as DataImportResult | undefined;
  }, [dataImport]);

  return (
    <Card>
      <Card.Heading>{dataImport.name}</Card.Heading>
      <Card.Description>
        {dayjs(dataImport.createdAt).utcOffset(new Date().getTimezoneOffset()).format('YYYY-MM-DD HH:mm:ss')}
      </Card.Description>
      <Card.Tags>
        {result && (
          <Badge
            text={result.type === 'success' ? 'Success' : 'Failed'}
            color={result.type === 'success' ? 'green' : 'red'}
          />
        )}
      </Card.Tags>
      <Card.Actions>
        <Button key="settings" variant="secondary" icon="edit" onClick={onEditClick}>
          Edit
        </Button>
        <Button key="explore" variant="destructive" icon="trash-alt" onClick={() => handleDelete()}>
          Delete
        </Button>
      </Card.Actions>
      {ConfirmComponent}
      {/* {isOpen && <DatasourceEditDrawer datasource={datasource} onClose={() => setIsOpen(false)} onEdited={onEdited} />} */}
    </Card>
  );
};

export default DataflowListItem;
