import { WS_API_URL } from 'common';
import React, { useEffect, useMemo, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import DataflowListItem from './DataflowListItem';
import { Dataflow, FunctionType } from '../../types';
import dayjs from 'dayjs';
import { currentProjectIdObservable, DEFAULT_RUNTIME_STATE, runtimeStateObservable } from '../../observables';
import useObservable from '../../hooks/useObservable';
import { Icon, Input, MultiSelect } from '@grafana/ui';

const DataflowList = () => {
  const [dataflows, setDataflows] = useState<Dataflow[]>([]);
  const projectId = useObservable(currentProjectIdObservable, 0);
  const activeProjectId = useObservable(runtimeStateObservable, DEFAULT_RUNTIME_STATE).activeProjectId;
  const [keywords, setKeywords] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState<Array<'RUNNING' | 'ERROR' | 'STOPPED' | 'INITIALIZING'>>([]);

  const filteredDataflows = useMemo(() => {
    if (!keywords && selectedStatuses.length === 0) {
      return dataflows;
    }
    return dataflows.filter((dataflow) => {
      return (
        (keywords === '' ||
          dataflow.name.toLowerCase().includes(keywords.toLowerCase()) ||
          dataflow.description?.toLowerCase().includes(keywords.toLowerCase())) &&
        (selectedStatuses.length === 0 || selectedStatuses.includes(dataflow.status.status))
      );
    });
  }, [dataflows, keywords, selectedStatuses]);

  useEffect(() => {
    if (projectId === 0) {
      return;
    }

    const ws = new ReconnectingWebSocket(WS_API_URL + '/api/dataflows/watch?project_id=' + projectId);

    ws.onmessage = (event) => {
      const dataflows = JSON.parse(event.data) as Dataflow[];
      setDataflows(dataflows.sort((a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1)));
    };

    return () => {
      ws.close();
    };
  }, [projectId]);

  useEffect(() => {
    if (projectId !== activeProjectId) {
      setSelectedStatuses([]);
    }
  }, [projectId, activeProjectId]);

  return (
    <div className="flex flex-col">
      <div className="flex mb-2 p-1 space-x-1">
        <Input
          placeholder="Search Dataflows"
          prefix={<Icon name="search" />}
          value={keywords}
          onChange={(e) => setKeywords(e.currentTarget.value)}
        />
        {projectId === activeProjectId && (
          <MultiSelect
            className="max-w-56"
            placeholder="Status"
            options={[
              { label: 'Running', value: 'RUNNING' },
              { label: 'Error', value: 'ERROR' },
              { label: 'Stopped', value: 'STOPPED' },
            ]}
            value={selectedStatuses}
            onChange={(v) => {
              setSelectedStatuses(v.map((item) => item.value as 'RUNNING' | 'ERROR' | 'STOPPED'));
            }}
          />
        )}
      </div>
      {filteredDataflows.map((dataflow) => (
        <DataflowListItem key={dataflow.id} dataflow={dataflow} />
      ))}
      {dataflows.length === 0 ? (
        <div className="p-2 text-center text-gray-500">No dataflows created yet</div>
      ) : filteredDataflows.length === 0 ? (
        <div className="p-2 text-center text-gray-500">No dataflows found</div>
      ) : null}
    </div>
  );
};

export default DataflowList;
