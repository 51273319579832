import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Modal } from '@grafana/ui';
import { POST, PUT } from 'client';
import React from 'react';
import type { ProjectUser } from '../../types';
import UserForm, { UserFormValues } from './UserForm';
import { currentProjectIdObservable } from '../../observables';

export default function UserEditModal({
  user,
  isMe,
  onClose,
}: {
  user: ProjectUser;
  isMe: boolean;
  onClose: () => void;
}) {
  const handleSaveUser = async (values: UserFormValues) => {
    const appEvents = getAppEvents();
    try {
      if (values.name !== user.name || values.email !== user.email || values.login !== user.login) {
        const response = await PUT('/api/users/{user_id}', {
          params: {
            path: {
              user_id: user.userId,
            },
          },
          body: {
            ...values,
            theme: '',
          },
        });
        if (response.error) {
          throw new Error(response.error.message);
        }
      }
      if (values.role !== user.userRole) {
        await PUT('/api/projects/{project_id}/users/{user_id}/role', {
          params: {
            path: {
              project_id: currentProjectIdObservable.value,
              user_id: user.userId,
            },
          },
          body: {
            role: values.role,
          },
        });
      }
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['User started successfully'],
      });
      onClose();
    } catch (e) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Failed to start user: ${e instanceof Error ? e.message : e}`],
      });
    }
  };

  const handleEnableUser = async () => {
    const appEvents = getAppEvents();
    try {
      await POST('/api/projects/{project_id}/users/{user_id}/enable', {
        params: {
          path: {
            project_id: currentProjectIdObservable.value,
            user_id: user.userId,
          },
        },
      });
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['User enabled successfully'],
      });
    } catch (e) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Failed to enable user: ${e instanceof Error ? e.message : e}`],
      });
    }
  };

  const handleDisableUser = async () => {
    const appEvents = getAppEvents();
    try {
      await POST('/api/projects/{project_id}/users/{user_id}/disable', {
        params: {
          path: {
            project_id: currentProjectIdObservable.value,
            user_id: user.userId,
          },
        },
      });
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['User disabled successfully'],
      });
    } catch (e) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Failed to disable user: ${e instanceof Error ? e.message : e}`],
      });
    }
  };

  return (
    <Modal title="Edit User" isOpen={true} onDismiss={onClose}>
      <UserForm
        onSubmit={handleSaveUser}
        defaultValues={{ ...user, role: user.userRole || 'Client', password: '' }}
        actions={
          <Modal.ButtonRow>
            <Button variant="secondary" fill="outline" onClick={onClose}>
              Cancel
            </Button>
            {!isMe && (
              <>
                {!user.isDisabled ? (
                  <Button variant="destructive" onClick={handleDisableUser}>
                    Disable
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleEnableUser}>
                    Enable
                  </Button>
                )}
              </>
            )}
            <Button type="submit">Save</Button>
          </Modal.ButtonRow>
        }
      />
    </Modal>
  );
}
