import React, { useEffect, useMemo, useRef, useState } from 'react';
import useConfirm from '../../hooks/useConfirm';
import {
  Alert,
  Badge,
  Button,
  Drawer,
  DropzoneFile,
  Form,
  FormsOnSubmit,
  HorizontalGroup,
  Icon,
  IconButton,
  Input,
} from '@grafana/ui';
import DataImportForm, { DataImportFormValues } from './DataImportForm';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { DataImport, DataImportResult } from '../../types';
import UpdateIsDirty from '../utils/UpdateIsDirty';
import { API_URL } from 'common';
import 'react-json-view-lite/dist/index.css';
import { PUT } from '../../client';
import { currentProjectIdObservable } from '../../observables';
import { transformLocalName } from '../../utils';

interface DataImportEditDrawerProps {
  dataImport: DataImport;
  onClose: () => void;
}

const DataImportEditDrawer: React.FC<DataImportEditDrawerProps> = ({ dataImport, onClose }) => {
  const [consoleText, setConsoleText] = useState<string>('');
  const [ConfirmModalComponent, showConfirm] = useConfirm({
    title: 'Unsaved Changes',
    body: 'You have unsaved changes, are you sure you want to discard it?',
    confirmText: 'Discard',
    onConfirm: onClose,
  });
  const abortController = useRef<AbortController | null>(null);
  const isDirtyRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const result = useMemo(() => {
    return dataImport.result as undefined as DataImportResult | undefined;
  }, [dataImport]);
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [files, setFiles] = useState<DropzoneFile[]>([]);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  const handleSubmit: FormsOnSubmit<DataImportFormValues> = async (data) => {
    try {
      setIsLoading(true);
      abortController.current?.abort();
      setConsoleText('');
      abortController.current = new AbortController();
      const formData = new FormData();
      formData.set(
        'importFunction',
        typeof data.importFunction === 'number' ? data.importFunction.toString() : JSON.stringify(data.importFunction)
      );
      formData.set('importFunctionArgs', JSON.stringify(data.importFunctionArgs));
      files.forEach((file) => {
        formData.append('file', file.file);
      });
      const response = await fetch(`${API_URL}/api/data-imports/${dataImport.id}/import`, {
        method: 'POST',
        body: formData,
        headers: {
          'project-id': currentProjectIdObservable.value.toString(),
        },
        signal: abortController.current.signal,
      });
      const appEvents = getAppEvents();
      if (!response.ok) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`DataImport edit failed: ${response.statusText}`],
        });
        return;
      }
      const reader = response.body?.getReader();
      if (!reader) {
        return;
      }
      const decoder = new TextDecoder();
      let done = false;
      while (!done && isLoadingRef.current) {
        const { done: _done, value } = await reader.read();
        done = _done;
        setConsoleText((prev) => prev + decoder.decode(value));
      }
      setIsLoading(false);
    } catch (err) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        return;
      }
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`DataImport edit failed: ${err}`],
      });
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (!isDirtyRef.current) {
      onClose();
      return;
    }
    showConfirm();
  };

  return (
    <Drawer
      title={
        <>
          <div className="flex items-center p-4 pb-6 relative">
            {!isNameEditing ? (
              <>
                <p className="text-2xl font-medium mb-0 mr-4">{dataImport.name}</p>
                <IconButton
                  name="pen"
                  aria-label="edit name"
                  size="lg"
                  onClick={() => {
                    setIsNameEditing(true);
                  }}
                />
              </>
            ) : (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  setIsNameEditing(false);
                  const formValue = new FormData(e.currentTarget);
                  const appEvents = getAppEvents();
                  try {
                    const response = await PUT('/api/data-imports/{id}', {
                      params: {
                        path: {
                          id: dataImport.id,
                        },
                      },
                      body: {
                        name: formValue.get('name') as string,
                      },
                    });
                    if (response.error) {
                      appEvents.publish({
                        type: AppEvents.alertError.name,
                        payload: [`Data Import edit failed: ${response.error.message}`],
                      });
                    } else {
                      appEvents.publish({
                        type: AppEvents.alertSuccess.name,
                        payload: ['Data Import name updated successfully'],
                      });
                    }
                  } catch (e) {
                    appEvents.publish({
                      type: AppEvents.alertError.name,
                      payload: [`Data Import edit failed: ${e instanceof Error ? e.message : e}`],
                    });
                  }
                }}
                className="flex items-center space-x-2"
              >
                <Input type="text" name="name" defaultValue={dataImport.name} className="min-w-[400px] max-w-full" />
                <Button type="submit" size="md">
                  Save
                </Button>
              </form>
            )}
            <IconButton
              name="times"
              aria-label="close drawer"
              className="absolute top-4 right-4 h-4 w-4"
              onClick={handleClose}
            />
          </div>
          <div className="bg-gray-700 h-px w-full" />
        </>
      }
      size="md"
      onClose={handleClose}
      scrollableContent
    >
      <>
        <Form
          defaultValues={{
            files: dataImport.files,
            // @ts-ignore
            importFunction: dataImport.importFunction?.function.importId
              ? dataImport.importFunction
              : dataImport.importFunction?.function.id ?? null,
            importFunctionArgs: dataImport.importFunctionArgs,
          }}
          className="p-4"
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <UpdateIsDirty isDirtyRef={isDirtyRef} isDirty={props.formState.isDirty} />
              <DataImportForm {...props} console={consoleText} files={files} setFiles={setFiles} />
              {!isLoading && (
                <>
                  {result?.type === 'success' && (
                    <Alert title="Import Success" severity="success">
                      {dataImport.files.length > 0 && (
                        <>
                          <p className="text-base text-gray-500 dark:text-gray-300 m-0">Files</p>
                          <div className="space-y-2 mt-1 mb-2">
                            {dataImport.files.map((file) => (
                              <Badge text={file.replace(/^tmp\/files\/\d+\//, '')} color="orange" key={file} />
                            ))}
                          </div>
                        </>
                      )}
                      <p className="text-base text-gray-500 dark:text-gray-300 m-0">
                        Imported {Object.values(result.data).reduce((acc, cur) => acc + cur, 0)} points
                      </p>
                      <div className="space-y-2 mt-1">
                        <div className="flex flex-wrap gap-1">
                          {result.registered.map((registry) => (
                            <Badge text={transformLocalName(registry.name)} color="green" key={registry.name} />
                          ))}
                        </div>
                      </div>
                    </Alert>
                  )}
                  {result?.type === 'error' && (
                    <Alert title="Import Failed" severity="error">
                      {dataImport.files.length > 0 && (
                        <>
                          <p className="text-base text-gray-500 dark:text-gray-300 m-0">Files</p>
                          <div className="space-y-2 mt-1 mb-2">
                            {dataImport.files.map((file) => (
                              <Badge text={file.replace(/^tmp\/files\/\d+\//, '')} color="orange" key={file} />
                            ))}
                          </div>
                        </>
                      )}
                      <p className="text-base dark:text-gray-300">{result.message}</p>
                    </Alert>
                  )}
                </>
              )}
              {result && (
                <p className="text-sm text-gray-500">
                  Import will remove all previously imported data in this &quot;Data Import&quot;.
                </p>
              )}
              <HorizontalGroup spacing="md">
                <Button type="submit" disabled={isLoading}>
                  {isLoading && <Icon name="fa fa-spinner" className="mr-2" />}
                  {!isLoading ? 'Save And Import' : 'Importing...'}
                </Button>
                {isLoading && (
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      setIsLoading(false);
                      abortController.current?.abort();
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </HorizontalGroup>
            </>
          )}
        </Form>
        {ConfirmModalComponent}
      </>
    </Drawer>
  );
};

export default DataImportEditDrawer;
